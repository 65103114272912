@import "../../assets/styles/mixins";

.platform {
  padding: 60px 5.65% 60px 5.65%;
  &_heading {
    @include font(24px, 150%, 700, var(--fill-black));
  }
}
.cardSection_wrapper {
  display: flex;
  margin-top: 32px;
  gap:24px;
}
.cardInfo {
  height: "auto";
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  margin-bottom: 24px;
  box-shadow: 0px 8px 20px rgba(127, 109, 109, 0.15);
  @include media-lg{
    margin-right: 0px;
  }
  &_heading {
    @include font(18px, 0px, 700, var(--fill-black));
    padding: 24px 16px 8px 16px;
  }
  &_subHeading {
    @include font(14px, 19.6px, 500, var(--fill-neutral-gray));
    padding: 12px 16px;
    height:150px;
    @include media-md{
      height: 116px;
    }
  }
  &_tag {
    display: flex;
    padding: 0px 16px;
    justify-content: space-between;
  }
  &_knowMore {
    @include font(16px, 19.6px, 700, #0067e2);
    margin-bottom: 16px;
  }
  &_img {
    border-radius: 8px 8px 0px 0px;
  }
}
.platformFormSection {
  padding:0px 40px;
  padding-top: 24px;
  @include media-md {
    padding: 0px 24px;
  }
}
.platformrequirementFormClass{
  width:432px;
  @include media-lg{
    width:300px;
  }
}
@include media-lg {
  .cardSection_wrapper {
    flex-direction: column;
    margin-top: 32px;
    align-items: center;
  }
  .platformFormSection {
    padding-top: 24px;
  }
}


.platformModal {
  display: flex;
  flex-direction: row;

  @include media-md {
    flex-direction: column;
  }
}
.platformImgSection {
   height: 480px;
  @include media-md {
     height: 200px;
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 8px 0px 0px 8px;
    @include media-md{
      border-radius:8px 8px 0px 0px;
    }
  }
}
.platformModalPadding {
  padding: 0px;
}
.modalClose {
  width: 24px;
  cursor: pointer;
  margin-top: 8px;
}
.modalHeading {
  @include font(18px, 0px, 700, var(--fill-black));
  margin: revert;
}
// .footerClass{
//   position:absolute;
//   padding-right: 40px;
//   bottom:16px;
//   width:-webkit-fill-available;
//   @include media-md{
//     position: relative;
//    padding-right: 0px;
//   }
// }
