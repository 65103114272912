@import "../../../assets/styles/mixins";

.sustain-hero {
  padding: 76px 11.25% 100px;
  &__home {
    position: relative;
    background-color: white;
    border-radius: 16px;
    height: 582px;
    img {
      height: 650px;
      width: 100%;
    }
    .hero-mobile{
      display: none;
    }
    .info {
      position: absolute;
      padding: 40px;
      background: rgba(255, 255, 255, 0.68);
      box-shadow: -4px 0px 12px rgba(242, 242, 242, 0.6);
      backdrop-filter: blur(15.5px);
      border-radius: 16px;
      left: 3%;
      top: 32%;
      .heading {
        font-size: 34px;
        font-weight: 800;
        max-width: 440px;
        line-height: 46px;
      }
      .para {
        max-width: 394px;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        padding-top: 16px;
        color: var(--fill-neutal-gray);
      }
    }
  }
}
@include media-lg {
    .sustain-hero {
      padding: 0px;
      &__home {
        height: auto;
        img{
            border-radius: 0px;
            height: 80vh;
            object-fit: cover;
            object-position: 80%;
        }
        .info {
          backdrop-filter: initial;
          padding-left: 6.25%;
          position: initial;
          padding-top: 16px;
          padding-bottom: 24px;
        }
      }
    }
  }
  @include media-sm {
    .sustain-hero {
      padding: 0px;
      &__home {
        img {
          border-radius: 0px;
          object-fit: cover;
          height: 62vh;
        }
        .hero-desktop{
          display: none;
        }
        .hero-mobile{
          display: flex;
        }
        .info {
          padding-left: 6.25%;
          padding-right: 6.25%;
          position: initial;
          .heading {
            font-weight: 800;
            font-size: 30px;
            line-height: 41px;
          }
          .para {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            padding-top: 1rem;
            padding-bottom: 1rem;
            color: var(--fill-neutral-black);
          }
        }
      }
    }
  }