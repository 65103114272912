@import "../../assets/styles/mixins";

.buyingHouseContainer{

    background-color: #F2F8FF;
    padding: 80px 5.65%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include media-lg{
        flex-direction: column;
        height:auto
        
    }
}
.buyingHouseImg{
    height: 40vh;
    @include media-lg{
        height:auto
        
    }
}