@import "../../assets/styles/mixins";

.box-section {
  background-color: var(--fill-blue);
  padding: 68px 11.25%;
  @include flex(row, space-between, center);
  gap: 70px;
  &__left {
    @include flex(column, center, flex-start);
    h1 {
      font-size: 40px;
      font-weight: 800;
    }
    p {
      max-width: 314px;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      padding-bottom: 16px;
    }
    .btn {
      width: 312px;
      border-radius: 8px;
      font-size: 18px;
      height: 56px;
    }
  }
  &__right {
    display: flex;
    gap: 24px;
    .section {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .row {
        display: flex;
        gap: 16px;
      }
    }
  }
}
@include media-xl {
  .box-section {
    flex-direction: column;
    gap: 48px;
    &__left {
      @include flex(column, center, center);
      h1 {
        text-align: center;
      }
      p {
        text-align: center;
      }
    }
  }
}
@include media-md {
  .box-section {
    padding: 64px 6.25%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    background-color: white;
    &__left {
      flex-direction: column;
      align-items: flex-start;
      max-width: 312px;
      h1 {
        text-align: left;
      }
      p {
        font-weight: 400;
        line-height: 21px;
        padding-bottom: 16px;
        text-align: left;
      }
      .btn {
        max-width: 310px;
      }
    }
    &__right-mobile {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      .special {
        width: 100%;
        height: auto;
      }
      .row {
        display: flex;
        gap: 1rem;
        @media (min-width: 300px) and (max-width: 340px) {
          gap: 0.7rem;
        }
        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
@include media-sm {
  .box-section {
    &__left {
      h1 {
        font-size: 30px;
      }
      p {
        font-size: 16px;
      }
    }
  }
}
@media (min-width: 300px) and (max-width: 350px) {
  .box-section {
    &__left {
      .btn {
        max-width: 285px;
      }
    }
  }
}
