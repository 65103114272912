@import "../../../assets/styles/mixins";

.feature {
  padding: 76px 11.25% 32px 11.25%;
  &__home {
    position: relative;
    background-color: white;
    height: 582px;
    img {
      width: 100%;
    }
    video {
      filter: brightness(100%);
      height: 582px;
      width: 100%;
      border-radius: 16px 16px 0px 0px;
    }

    .info {
      position: absolute;
      left: 3%;
      top: 23%;
      background: rgba(255, 255, 255, 0.5);
      box-shadow: -4px 0px 12px rgba(242, 242, 242, 0.6);
      backdrop-filter: blur(15.5px);
      padding: 40px;
      border-radius: 16px;
      max-width: 460px;
      .heading {
        font-size: 34px;
        font-weight: 800;
        max-width: 440px;
        line-height: 46px;
      }
      .para {
        max-width: 410px;
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        padding-top: 16px;
        padding-bottom: 40px;
      }
      .btn {
        width: 364px;
        border-radius: 8px;
        font-size: 18px;
        height: 56px;
      }
    }
  }
  &__features {
    background-color: var(--fill-blue);
    display: flex;
    justify-content: space-between;
    padding: 32px 56px;
    border-radius: 0px 0px 16px 16px;
    .feature-box {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      .name {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1310px) {
  .feature {
    &__home {
      .info {
        .heading {
          font-size: 38px;
          max-width: 382px;
        }
        .para {
          max-width: 344px;
        }
      }
    }
  }
}
@include media-lg {
  .feature {
    padding: 0px;
    &__home {
      height: auto;
      video {
        object-fit: cover;
        width: 100%;
        left: 0%;
        height: 70vh;
      }
      .info {
        padding-left: 6.25%;
        position: initial;
        padding-top: 16px;
        padding-bottom: 24px;
        background: white;
        box-shadow: none;
        backdrop-filter: initial;
      }
    }
    &__features {
      @include flex(column, center, flex-start);
      background: white;
      padding-left: 6.25%;
      gap: 26px;
    }
  }
}
@include media-sm {
  .feature {
    background-color: white;
    padding: 0px;
    &__home {
      padding-top: 0px;
      video {
        border-radius: 0px;
        object-fit: cover;
        height: 62vh;
      }
      .info {
        padding-left: 6.25%;
        padding-right: 6.25%;
        position: initial;
        .heading {
          font-weight: 800;
          font-size: 30px;
          line-height: 41px;
        }
        .para {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          padding-top: 1rem;
          padding-bottom: 1rem;
        }
        .btn {
          width: 312px;
        }
      }
    }
    &__features {
      padding-left: 6.25%;
      .feature-box {
        .name {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }
}
@media (min-width: 300px) and (max-width: 350px) {
  .feature {
    &__home {
      .info {
        .btn {
          max-width: 285px;
        }
      }
    }
  }
}
