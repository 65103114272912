$full-width: 360px;
$half-width: 216px;

$full-width-percent: 100%;
$half-width-percent: 58%;

.carouselView {
  @include flex(row, left, flex-start);
  width: 992px;
  margin-left: auto;
  margin-right: auto;

  img {
    width: $full-width-percent;
  }

  // .carouselStep {
  //   position: relative;
  //   transition: width 0.2s ease-in;
  //   width: $half-width-percent;
  //   overflow: hidden;
  //   margin-left: 2px;

  //   &:first-of-type {
  //     border-top-left-radius: 4px;
  //     border-bottom-left-radius: 4px;
  //   }
  //   &:last-of-type {
  //     border-top-right-radius: 4px;
  //     border-bottom-right-radius: 4px;
  //   }
  //   &--active {
  //     width: $full-width-percent;
  //   }
  // }

  @include media-sm;
  @include media-md;
  @include media-lg {
    flex-direction: column;
    width: 100%;

    // img {
    //   width: 100%;
    //   height: 100%;
    // }

    .carouselStep {
      height: 200px;
      margin-bottom: 2px;
      width: 100%;

      &:first-of-type {
        border-radius: 0px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      &:last-of-type {
        border-radius: 0px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      .title {
        @include font(14px, 18px, 700, #333340);
      }

      .description {
        @include font(14px, 18px, 500, #333340);
      }
    }
  }
}
