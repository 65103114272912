@import "../../assets/styles/mixins";

.careersWrapper {
  .jobsSectionWrapper {
    &__list {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 992px;
      margin: auto;
    }

    &__card {
      padding: 24px;
      box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
      border-radius: 8px;
      width: 312px;
      height: 200px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      img {
        margin-bottom: 40px;
      }

      &-description {
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;

        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &-metadata {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 16px;

        div {
          display: flex;
          align-items: center;
          &:first-child {
            margin-bottom: 16px;
          }
        }

        svg {
          margin-right: 8px;
        }
      }

      &:hover {
        box-shadow: 0px 2px 4px rgba(41, 41, 42, 0.07);
        .jobsSectionWrapper__card-description {
          color: var(--fill-primary);
          svg path {
            fill: var(--fill-primary);
          }
        }
      }
    }

    @include media-sm;
    @include media-md {
      &__list {
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
      }

      &__card {
        margin-bottom: 16px;
        box-shadow: 0px 0px 4px rgba(41, 41, 42, 0.12);
        width: 100%;
      }
    }
  }

  .careersAdvantageWrapper {
    .cardsSectionWrapper {
      &__benefits {
        max-width: 992px;
        grid-column-gap: 70px;
      }
      &__heading {
        padding-bottom: 56px;
      }
      .benefitCard__contentWrapper {
        max-width: 400px;
      }
    }
  }
}
