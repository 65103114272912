@import "../../assets/styles/mixins";

.knowMoreSection {
  padding: 80px 5.65%;
  background-color: #f2f8ff;
  @include media-lg {
    padding: 40px 6.25%;
  }
}
.heading {
  font-size: 34px;
  font-weight: 800;
  color: #00142d;
  margin: 0;
  @include media-md {
    font-size: 30px;
    line-height: 120%;
  }
}
.content_wrapper {
  display: flex;
  margin-top: 48px;
  gap:16px;
}
.content_container {
  background: white;
  border-radius: 16px;
  padding: 16px;
  // max-width: 400px;
  @include media-lg {
    // max-width: 800px;
    margin-bottom: 24px;
    margin-right: 0px;
  }
}
.content_heading {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
  min-height: 46px;
}
.content_dates{
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    color: #92979C;
    margin-top: 12px;
}
.content_subTxt {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: #303c4a;
}
.viewAllButton {
  display: flex;
  width: 118px;
  height: 32px;
  background-color: white;
  padding: 6px 14px;
  align-items: center;
  border-radius: 16px;
  margin-top: 48px;
}
.viewAllText {
  color: #0067e2;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin-right: 8px;
}
.viewButtonContainer {
  justify-content: center;
  align-items: center;
  display: flex;
}
@include media-lg {
  .content_wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 48px;
    align-items: center;
  }
  .heading {
    font-size: 30px;
    font-weight: 800;
    color: #00142d;
    line-height: 120%;
  }
}
