@import "../../assets/styles/mixins";

.page-header {
  position: fixed;
  width: 100%;
  z-index: 999;
  left: 0;
  top: 0;
  padding: 0px 5.65%;
  height: 56px;
  background: var(--fill-white);
  .link {
    color: var(--black);
    cursor: pointer;

    svg path {
      fill: var(--fill-black);
    }

    &--active {
      border-bottom: 2px solid var(--fill-black);
    }
  }

  @media (min-width: 768px) and (max-width: 744px) {
    padding: 0px 2.25% !important;
  }
  &.navSpacing {
    padding: 0 72px;
  }
  &.scrolledd {
    transition: background ease-in 0.2s;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
    .symboll {
      margin-right: 8px;
    }
    .locofastSymbol {
      margin-bottom: 2px;
    }
    .logo {
      filter: none;
    }
    .link {
      color: var(--black);
      cursor: pointer;

      svg path {
        fill: var(--fill-black);
      }

      &--active {
        border-bottom: 2px solid var(--fill-black);
      }
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  &__navItems {
    @include flex(row, center, center);
    height: 100%;
  }

  &.scrolledd .link-btn--desktop {
    border: 1px solid var(--fill-black);
    color: var(--fill-black);
    padding: 5px 16px;
  }
  &.scrolledd .link-btn--mobile.link-btn {
    border: 1px solid #d4d4d4;
    color: #0067e2 !important;
    background-color: white;
    padding: 5px 16px;
    border-radius: 16px;
    height: 32px;
  }
  .symboll {
    cursor: pointer;
    // filter: brightness(0) invert(1);
    margin-right: 0.5rem;
    margin-bottom: -2%;
  }
  .logo {
    cursor: pointer;
    filter: brightness(0) invert(1);
    margin-right: 0.5rem;
  }
  .link {
    @include flex(row, center, center);
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    height: 60%;
    margin-right: 32px;
    cursor: pointer;

    &--active {
      border-bottom: 2px solid var(--fill-black);
    }
    &--mobile {
      color: var(--fill-black);
      margin: 0px;
      margin-bottom: 32px;
      border: 1px solid black;

      svg path {
        fill: var(--fill-black);
      }
      &.link--active {
        border-bottom: 2px solid #0067e2;
        color: #0067e2;
      }
    }
  }

  .link-btn {
    @include flex(row, center, center);
    @include font(16px, 16.12px, 700, var(--fill-black));
    height: 36px;
    padding: 5px 16px !important;
    border-radius: 4px;
    border: 1px solid black;
    &--mobile {
      color: var(--fill-black);
      padding: 0px;
      border: 1px solid #d4d4d4;
      border-radius: 16px;
      color: #0067e2 !important;
      background-color: white;
      height: 32px;
      margin-bottom: 24px;
    }
  }
  .requestBtn {
    height: 36px;
    margin-left: 24px;
    width:184px
  }
  .requestCtaBtn {
    display: none;
  }
  .requestCtaBtn--active {
    display: block;
  }

  .menuIcon {
    display: none;
  }
  .menuDropdown {
    display: none;
  }
  @include media-md;
  @include media-lg {
    height: 56px;
    padding: 16px 24px;

    &.scrolledd {
      .menuIcon rect {
        fill: #0067e2;
      }
    }

    .requestCtaBtn {
      display: none;
    }
    .requestCtaBtn--active {
      display: block;
    }

    .logoo {
      margin-right: 0.5rem;
    }
    .logo-blue {
      margin-bottom: 4%;
    }

    .link {
      height: 130% !important;
    }
  }
  @media (max-width: 992px) {
    .logoo {
      margin-right: 0.5rem;
    }
    &__navItems {
      display: none;
    }
    .menuIcon {
      display: block;
    }
    .menuwrapp {
      height: 100vh;
      width: 100vw;
      top: 0;
      bottom: 0;
      left: 0;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.5);
    }

    .menuDropdown {
      @include flex(column, center, center);
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100vw;
      padding: 16px 24px 32px 24px;
      min-height: 204px;
      background-color: var(--fill-blue-light);

      &__iconWrapper {
        @include flex(row, space-between, center);
        width: 100%;
      }
      &__items {
        @include flex(column, center, center);
        flex-grow: 1;
      }
    }
  }
  @include media-sm {
    padding: 16px 24px;
    &__navItems {
      display: none;
    }
    .menuIcon {
      display: block;
    }
    .menuDropdown {
      @include flex(column, center, center);
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100vw;
      padding: 16px 24px 32px 24px;
      min-height: 204px;
      background-color: var(--fill-blue-light);

      &__iconWrapper {
        @include flex(row, space-between, center);
        width: 100%;
      }
      &__items {
        @include flex(column, center, center);
        flex-grow: 1;
      }
    }
  }
  @include media-sm {
    padding: 16px 24px;
    &.navSpacing {
      padding: 16px 24px;
    }
  }
  @include media-lg {
    background-color: rgba(0, 0, 0, 0.1);
    &.scrolledd {
      background: white;
    }
  }
}
