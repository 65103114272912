@import "../../assets/styles/mixins";
@import "../../assets/styles/breakpoints";

.adwordsContainer {
  position: relative;

  // Blue strip at the bottom of the page
  &::after {
    position: absolute;
    content: "";
    height: 32px;
    background-color: var(--fill-footer);
    width: 100%;
  }

  &__form {
    width: 360px;
    position: fixed;
    right: 72px;
    top: 59%;
    transform: translateY(-50%);
    background: transparent;
    height: 600px;
    z-index: 23;
    @include media-xl {
      right: 16px;
    }
  }

  &__content {
    .section {
      &__heading {
        font-weight: 800;
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 16px;
        margin-top: -15px;
        width: 70%;
        @media (min-width: 1350px) and (max-width: 1450px) {
          width: 83%;
        }
      }

      &__subheading {
        font-size: 16px;
        line-height: 24px;
        max-width: 724px;
      }

      &__item_heading {
        font-weight: 700;
        font-size: 18px;
      }

      &__item_subheading {
        line-height: 20px;
      }
    }

    .customersSection {
      padding: 82px 72px 72px 72px;
      text-align: center;
      width: calc(100vw - 416px);
      .customers {
        display: flex;
        align-items: center;
        gap: 32px;
        padding-top: 16px;
        @media (max-width: 1300px) {
          gap: 12px;
        }
        @include media-lg {
          img {
            width: 100%;
          }
        }
      }
      .mobile {
        display: none;
      }
      &__meta {
        font-size: 18px;
        font-weight: 400;
        color: var(--fill-neutral-gray);
        display: inline-flex;
        padding: 8px;
        border-radius: 32px;
        margin-top: 32px;
        width: 45%;
        @media (min-width: 1350px) and (max-width: 1450px) {
          width: 36%;
        }
      }
      @include media-lg {
        padding-left: 20px;
        .heading {
          text-align: left;
        }
        &__meta {
          width: 73%;
        }
      }
      @include media-md {
        .heading {
          text-align: center;
        }
      }
    }

    .fabricsRangeSection {
      @include content-spacing;
      background-color: var(--fill-deep-gray2);
      &__part {
        width: calc(100vw - 590px);
        .fabricsRange {
          padding-left: 72px;
          padding-bottom: 82px;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          overflow-x: auto;
          justify-content: space-between;
          gap: 0px;
          &__item {
            &:not(:last-child) {
              margin-right: 24px;
            }
            height: 277px;
            width: 226px;
            padding: 16px;
            background-color: white;
            border-radius: 16px;
            img {
              width: 194px;
              height: 96px;
              border-radius: 8px;
            }

            .section__item_heading {
              padding-top: 16px;
              padding-bottom: 8px;
            }
            .para {
              color: var(--fill-neutral-gray2);
            }
          }
        }
        .fabricsRange::-webkit-scrollbar {
          display: none;
        }
        .fabricsRange {
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
        }
        .mainContent {
          padding: 82px 72px 0px 72px;
          .top {
            display: flex;
            justify-content: space-between;
            @include media-lg{
              .heading{
                width: 100%;
              }
            }
            .arrw {
              margin-right: -5rem;
              margin-top: -28px;
              img {
                height: 13px;
              }
              @include media-lg {
                display: none;
              }
            }
          }
        }
      }
    }

    .servicesSection {
      padding: 82px 72px;
      @include content-spacing;

      .services {
        display: grid;
        grid-template-columns: 0.2fr 0.2fr;
        grid-row-gap: 48px;
        grid-column-gap: 30px;
        &__item {
          display: flex;
          flex-direction: column;
          border: 0.690141px solid #cee4ff;
          border-radius: 16px;
          width: 352px;
          .box {
            padding: 24px;
          }
          img {
            width: 352px;
            height: 170px;
            border-radius: 16px 16px 0px 0px;
          }
          .section__item_heading {
            padding-bottom: 8px;
          }
        }
      }
      @media (max-width: 1300px) {
        .subtext {
          max-width: 510px;
        }
        .services {
          grid-template-columns: 1fr;
        }
      }
      @include media-lg {
        .text,
        .subtext {
          max-width: 330px;
        }
      }
      @include media-md {
        .text {
          max-width: 264px;
        }
        .subtext {
          max-width: 312px;
        }
      }
    }

    .cardsSectionWrapper {
      padding: 82px 72px;
      text-align: center;
      width: calc(100vw - 416px);

      &__heading {
        font-size: 32px;
        font-weight: 800;
        width: 70%;
        padding-bottom: 1rem;
        @media (min-width: 1350px) and (max-width: 1450px) {
          width: 83%;
        }
      }

      &__benefits {
        @include description-spacing;
      }

      .benefitCard {
        max-width: 350px;

        &__iconWrapper {
          width: 40px;
          svg {
            transform: scale(0.82);
          }
        }
      }
    }
    /********************************/

    .testimonialsSection {
      &__list {
        @include description-spacing;
      }
    }

    .aboutSection {
      padding: 52px 72px;
      text-align: center;
      width: calc(100vw - 416px);
      display: flex;

      img {
        width: 446px;
        height: 512px;
      }
    }
    @include media-lg {
      .aboutSection {
        padding: 60px 6.25%;
        width: 100%;
        flex-direction: column;
      }
    }
    @include media-sm {
      .aboutSection {
        padding: 60px 6.25% 0px 6.25%;
        .content {
          padding: 32px 0px 64px 0px;
          img {
            width: 97%;
            height: 360px;
          }
        }
      }
    }
  }

  // Visible only in mobile view
  &__customernote {
    padding: 40px 8px 0px 8px;
    text-align: center;
    display: none;
  }

  .customerNote {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    text-align: center;

    background-color: var(--fill-white);
    padding: 0 15px 24px;
    > div {
      color: var(--fill-dark-gray-2);
      padding: 0 12px 10px 12px;
      font-size: 13px;
      font-weight: 400;
      .text-light {
        color: var(--fill-dark-gray-3);
      }
    }

    img {
      background-color: var(--fill-white);
      width: 32px;
      height: 14px;
      text-align: center;
      display: block;
      padding: 0 8px;
      z-index: 3;
      position: relative;
      top: -6px;
    }

    &::after {
      content: "";
      position: absolute;
      width: calc(100% - 40px);
      height: calc(100% - 24px);
      border-radius: 4px;
      border: 1px solid var(--fill-light-gray);
    }
  }
  .customerNoteBottomSheet {
    padding: 0px;
    &::after {
      width: calc(100% - 5px);
      height: 88%;
    }
    margin-bottom: 1rem;
  }
}

:root {
  --form-container-width: 416px;
}
.aboutSection {
  .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title {
      text-align: left;
      margin-top: 70px;
      margin-bottom: 8px;
      width: 100%;
    }
    .para {
      text-align: left;
      width: 312px;
      font-size: 18px;
      font-weight: 400;
    }
    .download {
      display: flex;
      justify-content: flex-start;
      margin-top: 16px;
    }
  }
  .content {
    padding-left: 72px;
  }
}
.servicesPartners {
  padding: 82px 72px;
  background-color: var(--fill-deep-gray2);
  color: var(--fill-neutral-gray);
  .heading {
    font-size: 20px;
    line-height: 25px;
    font-weight: 800;
  }
  &__content {
    margin-top: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    max-width: 778px;
  }
}
@media (max-width: 1300px) {
  .servicesPartners {
    &__content {
      padding-right: 10%;
    }
  }
}
@media (max-width: 1300px) {
  .aboutSection {
    flex-direction: column;
    align-items: flex-start;
    .content {
      padding-left: 0px;
    }
  }
}
@include media-xl {
  .servicesPartners {
    &__content {
      max-width: 620px;
    }
  }
}
@include media-lg {
  .servicesPartners {
    &__content {
      max-width: 420px;
    }
  }
}
@include media-md {
  .aboutSection {
    align-items: center;
    .left {
      .title {
        text-align: center;
        margin-top: 0px;
      }
      .para {
        font-size: 16px;
        width: auto;
        max-width: 320px;
        text-align: center;
      }
      .download {
        justify-content: center;
      }
    }
    .content {
      padding-left: 0px;
    }
  }
}
@include media-md {
  .servicesPartners {
    padding: 60px 20px;
    .heading {
      text-align: center;
    }
    &__content {
      max-width: fit-content;
      padding-right: initial;
    }
  }
}
