@import "../../assets/styles/mixins";
@import "../../assets/styles/carousel.scss";

.platformWrapper {
  @include flex(column, flex-start, flex-start);
  width: 100vw;
  padding: 56px 11.25% 56px 11.25%;

  &__stepsWrapper {
    margin-bottom: 56px;
  }
}

@include media-lg {
  .platformWrapper {
    &__fabricTypesWrapper {
      @include flex(row, flex-start, flex-start);
      width: 100%;
      overflow-x: scroll;
      overflow-y: hidden;
      &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
      }
    }
  }
}

@include media-sm;
@include media-md;
@include media-lg {
  .platformWrapper {
    padding: 56px 6.25% 56px 6.25%;
  }

  .carouselView {
    &.platformWrapper__fabricTypesWrapper {
      display: grid;
      padding: 24px 24px 56px 0px;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 16px;
      .carouselStep {
        overflow: visible;

        margin-bottom: 2px;
      }
      .carouselStep img {
        width: 77.69px;
      }

      .carouselStep {
        margin-right: 16px;
        height: 100%;

        img,
        .title {
          border-radius: 8px;
          margin: 0px;
        }
      }
    }
  }
}
@include media-lg {
  .carouselView {
    &.platformWrapper__fabricTypesWrapper {
      display: grid;
      padding: 24px 24px 40px 6.25%;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 16px;
    }
  }
}
