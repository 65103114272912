@import "../../assets/styles/mixins";

.conscious {
  padding: 80px 11.25%;
  background-color: var(--fill-green-secondary);
  display: flex;
  justify-content: space-between;
  .info {
    .header {
      display: flex;
      // justify-content: center;
      padding-bottom: 24px;
      .image {
        padding-right: 24px;
      }
      .heading {
        font-size: 34px;
        font-weight: 800;
        line-height: 44px;
      }
    }
    .header-info {
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      max-width: 382px;
      color: var(--fill-neutal-gray);
    }
    padding-bottom: 72px;
  }
  .right {
    &__images {
      display: flex;
      gap: 22px;
      .first {
        display: flex;
        flex-direction: column;
        gap: 22px;
        .special {
          width: 246px;
          height: 246px;
          border-radius: 16px;
          background-color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 0px 0px 3px rgba(0, 103, 226, 0.12);
          img {
            width: 70%;
          }
        }
        .special:hover {
          box-shadow: 0px 0px 4px var(--fill-neutral-green);
        }
        .two-logo {
          display: flex;
          justify-content: center;
          gap: 22px;
        }
      }
      .second {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 22px;
        grid-column-gap: 22px;
      }
      .image {
        width: 112px;
        height: 112px;
        border-radius: 16px;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 3px rgba(0, 103, 226, 0.12);
        img {
          width: 70%;
        }
      }
      .image:hover {
        box-shadow: 0px 0px 4px var(--fill-neutral-green);
      }
    }
    &__mobile {
      display: none;
    }
    .description {
      padding-top: 56px;
      .text {
        font-size: 28px;
        font-weight: 700;
        line-height: 36px;
      }
      .subtext {
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        padding-top: 16px;
        max-width: 520px;
        color: var(--fill-neutal-gray);
      }
    }
  }
}
@include media-xl {
  .conscious {
    flex-direction: column;
    align-items: center;
    .info {
      .header-info {
        text-align: center;
      }
    }
    .right {
      .text {
        text-align: center;
      }
      .subtext {
        text-align: center;
      }
    }
  }
}
@include media-sm {
  .conscious {
    padding: 64px 24px;
    .info {
      .header {
        flex-direction: column;
        padding-bottom: 16px;
        .image {
          @include communityImage;
        }
        .heading {
          font-size: 30px;
          line-height: 130%;
          text-align: center;
        }
      }
      .header-info {
        text-align: left;
      }
    }
    .right {
      &__images {
        display: none;
      }
      &__mobile {
        padding: 10px 8px;
        display: flex;
        overflow-x: scroll;
        max-width: 300px;
        justify-content: space-between;
        gap: 22px;
        &::-webkit-scrollbar {
          display: none;
        }
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        .mobile-image {
          width: 104px;
          height: 104px;
          background-color: white;
          border-radius: 16px;
          padding: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 0px 0px 3px rgba(0, 103, 226, 0.12);
          img {
            width: 72px;
            height: 72px;
          }
          .image2 {
            width: 86px;
            height: 42px;
          }
          .image8 {
            width: 90px;
            height: 70px;
          }
        }
        .mobile-image:hover {
          box-shadow: 0px 0px 4px var(--fill-neutral-green);
        }
      }
      .description {
        .text {
          text-align: left;
          font-size: 27px;
        }
        .subtext {
          text-align: left;
        }
      }
    }
  }
}
