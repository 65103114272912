@import "../../assets/styles/mixins";
.main {
  padding: 96px 11.25% 64px 11.25%;
  .har {
    font-family: Mulish;
    font-size: 44px;
    font-weight: 800;
    line-height: 52px;
    letter-spacing: 0px;
    padding-bottom: 48px;
  }
  .main-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .box-1 {
      display: flex;
      flex-direction: column;
      gap: 48px;
    }
    .box-2 {
      display: flex;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .harr {
    font-family: Mulish;
    font-size: 44px;
    font-weight: 800;
    line-height: 52px;
    letter-spacing: 0px;
    padding-top: 128px;
  }
  .main-2 {
    @include flex(row, space-between, center);
    .text1 {
      font-family: Mulish;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
      width: 41%;
    }
    .text2 {
      display: flex;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
@include media-lg {
  .main {
    padding: 0px;
    .har {
      text-align: center;
      margin: 0px;
      padding-top: 96px;
      padding-left: 11.25%;
      padding-right: 11.25%;
    }
    .main-1 {
      flex-direction: column-reverse;
      gap: 42px;
      .box-1 {
        align-items: center;
      }
      .box-2 {
        padding-left: 11.25%;
        padding-right: 11.25%;
        align-items: center;
      }
    }
    .harr {
      padding-top: 0px;
      text-align: center;
    }
    .main-2 {
      padding-left: 11.25%;
      padding-right: 11.25%;
      padding-bottom: 64px;
      @include flex(column, center, center);
      font-size: 4px;
      gap: 42px;
      .text1 {
        text-align: left;
        width: 100%;
        p {
          text-align: left;
        }
      }
      .text2 {
        align-items: center;
      }
    }
  }
}
@include media-md {
  .main {
    padding: 0px 0px 72px 0px;
    .har {
      padding-bottom: 24px;
      text-align: center;
    }
    .main-1 {
      flex-direction: column-reverse;

      .box-2 {
        align-items: center;
      }
    }
    .harr {
      text-align: center;
    }
    .main-2 {
      @include flex(column, center, center);
      font-size: 4px;
      .text1 {
        text-align: left;
        width: 100%;
        p {
          text-align: left;
          line-height: 148%;
        }
      }
      .text2 {
        align-items: center;
      }
    }
  }
}
@include media-sm {
  .main {
    padding: 0px 0px 72px 0px;
    .har {
      text-align: center;
      margin-top: 0px;
      padding-bottom: 0px;
      margin-bottom: 24px;
      font-size: 30px;
      font-weight: 800;
      line-height: 56px;
      letter-spacing: 0em;
      text-align: center;
    }
    .main-1 {
      flex-direction: column-reverse;
      .box-1 {
        align-items: center;
      }
      .box-2 {
        padding-left: 6.25%;
        padding-right: 6.25%;
        align-items: center;
      }
    }
    .harr {
      padding-top: 0px;
      margin-top: 72px;
      margin-bottom: 24px;
      text-align: center;
    }
    .main-2 {
      padding-left: 6.25%;
      padding-right: 6.25%;
      @include flex(column, center, center);
      font-size: 4px;
      .text1 {
        text-align: left;
        width: 100%;
        h3 {
          margin: 0px;
        }
        p {
          text-align: left;
        }
      }
      .text2 {
        align-items: center;
      }
    }
  }
}
@include media-lg {
  .awardWrapper {
    &__awardTypesWrapper {
      @include flex(row, flex-start, flex-start);
      width: 100%;
      overflow-x: scroll;
      overflow-y: hidden;
      padding-left: 11.25%;
      &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
      }
    }
  }
}
.awardWrapper {
  padding: 56px 6.25% 56px 6.25%;
}

.carouselView {
  &.awardWrapper__awardTypesWrapper {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-row-gap: 10px;
    .carouselStep,
    .carouselStep img {
      overflow: visible;
      width: 200px;
      margin-bottom: 2px;
    }

    .carouselStep {
      margin-right: 16px;
      height: 100%;

      img,
      .title {
        border-radius: 8px;
      }
    }
  }
}
