@import "../../assets/styles/mixins";

.design {
  margin-top: 96px;
  background: var(--fill-blue);
  position: relative;
  .left-box {
    position: absolute;
    left: 0px;
    width: 338px;
    z-index: 10;
    height: 280px;
    background: linear-gradient(
      90deg,
      #f9faff 23.78%,
      rgba(249, 250, 255, 0) 100%
    );
  }
  .right-box {
    position: absolute;
    right: 0px;
    width: 280px;
    z-index: 10;
    height: 280px;
    background: linear-gradient(
      90deg,
      #f9faff 23.78%,
      rgba(249, 250, 255, 0) 100%
    );
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  .slider {
    .slick-list {
      padding-top: 60px;
      padding-bottom: 60px;
      padding-left: 25px;
    }
    .slick-slide div {
      width: 160px;
      transition: transform 0.2s;
    }
    .slick-arrow {
      z-index: 10;
      height: 40px;
      width: 40px;
    }
    .slick-prev {
      left: 4.5%;
    }
    .slick-next {
      right: 2.5%;
    }
    .image {
      height: 155px;
      cursor: pointer;
    }
    .slick-slide div:hover {
      transform: scale(1.2);
    }
  }
  &__images {
    display: none;
  }
}
@include media-md {
  .design {
    .left-box,
    .right-box {
      display: none;
    }
    .slider {
      display: none;
    }
    &__images {
      padding: 0px 24px;
      display: flex;
      justify-content: space-between;
      gap: 16px;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
    .image {
      width: 80px;
      height: 80px;
      cursor: pointer;
    }
  }
}
@include media-sm {
  .design {
    margin-top: 48px;
    background-color: var(--fill-blue-primary-2);
    .slider {
      .slick-slide div {
        width: 80px;
        margin-left: 0px;
      }
      .image {
        height: 80px;
      }
      .slick-slide div:hover {
        width: 80px;
        height: 80px;
        margin-left: 0px;
      }
    }
  }
}
