.error {
  p {
    color: var(--fill-red);
    font-style: regular;
    font-size: 14px;
    line-height: 24px;
    margin: 0;
  }
}
.modal-error {
  margin-top: 8px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-20 {
  margin-bottom: 12px;
}

@media (max-width: 780px) {
  .modal-error {
    font-size: 12px;
    line-height: 20px;
    padding: 0;
    p {
      margin: 0;
    }
  }
}
