.bottomSheet {
  border-radius: 0;
  box-shadow: none;
  bottom: 0;
  width: 100%;
  z-index: 3;
  background-color: var(--fill-white);

  // Visible only in mobile view
  display: none;

  .btn-primary {
    margin: 8px 24px 10px;
    padding: 10px 0;
    width: calc(100% - 48px);
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    border-radius: 8px;
    height: 56px;
  }

  .adwordsFormContainer {
    &__header {
      padding: 8px 20px 16px 20px;
    }

    &__bs_success {
      display: flex;
      align-items:center;
      background-color: var(--fill-white);
      padding: 14px 24px;
      box-shadow: inset 0px 1px 0px rgba(0, 1, 38, 0.1);
      svg {
        width: 40px;
        height: 40px;
      }

      .heading {
        margin-left: 16px;
        color: var(--fill-dark-gray-3);
        font-weight: 600;
      }
    }
  }
  .formContent {
    display: block !important;
    margin: 0 24px;
  }
  .bottomSheetForm {
    z-index: 8;
    background-color: var(--fill-white);
    border-radius: 0;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 8px;

    button[type="submit"] {
      margin: 0 24px;
      padding: 10px ;
    }
  }

  .containerr {
    position: fixed;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 21;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
  }
  .backgroundContainer {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
