@import "../../assets/styles/mixins";

.productSelection_container {
  padding-top: 80px;
  @include media-md{
    padding-top: 40px;
  }
}

.productTitle {
  font-size: 34px;
  font-weight: 800;
  margin-left: 72px;
  @include media-md{
    margin:0px
  }
}
.productBox {
  display: flex;
  border-bottom: 1px solid #f2f2f2;
  justify-content: center;
  align-items: center;
  margin: 48px 72px 16px 72px;
}
.subContainer{
  display: flex;
  position: relative;
  overflow: auto;
  scroll-padding: 0px;
}
.subCategoryBox{
  display: flex;
  position: relative;
  overflow: auto;
}
.categoryBox {
  display: flex;
  margin-top: 16px;
  margin: 16px 24px 16px 24px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.productItem {
  margin-right: 48px;
  font-size: 18px;
  color: #626870;
  padding-bottom: 8px;
  cursor: pointer;
}
.selectedProductItem {
  border-bottom: 2px solid #0067e2;
  cursor: pointer
}
.selectedState {
  color: #0067e2;
  margin-right: 48px;
  font-size: 18px;
  font-weight: 700;
  @include media-lg{
    font-size: 16px;
  }
  @include media-md{
    font-size: 16px;
  }
}
.productCategoryBox {
  border: 1px solid #f2f2f2;
  margin-right: 8px;
  padding: 10px 16px;
  border-radius: 8px;
  color: #303c4a;
  font-size: 14px;
  min-width: 100px;
  justify-content: center;
  display: flex;
  cursor: pointer;
}
.selectedProductCategoryBox{
  background-color: #F2F8FF;
  margin-right: 8px;
  padding: 10px 16px;
  border-radius: 8px;
  min-width: 124px;
  justify-content: center;
  display: flex;
  cursor: pointer;
}
.selectedProductCategoryBoxTxt{
  color: #0067e2;
  margin-left: 8px;
  font-size: 14px;
  font-weight: 600;
}
.productselection {
  position: relative;
  margin-right: 24px;
  display: flex;
  justify-content: center;
  padding: 0px;
}
.text_block {
  position: absolute;
  bottom: 16px;
  font-size: 24px;
  font-weight: 700;
  color: white;
}
.gradientBox {
  background: linear-gradient(#00000000, #00000085);
  border-radius: 16px;
  height: 160px;
  width:208px
}
.product_img {
  z-index: -1;
  position: relative;
  width: 100%;
  height:100%
}
.arrowBackground {
  border-radius: 50%;
  background-color: #f2f8ff;
  padding: 10px;
  height: 40px;
  display: flex;

}
.main-scroll-div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 48px 16px 80px 16px;
}
.cover {
  position: relative;
  width: 90%;
  height: 50%;
}
.scroll-images {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: left;
  align-items: center;
  overflow: auto;
  position: relative;
  scroll-behavior: smooth;
}
.scroll-images::-webkit-scrollbar {
  -webkit-appearance: none;
}
.subContainer::-webkit-scrollbar {
  -webkit-appearance: none;
}
.subCategoryBox::-webkit-scrollbar {
  -webkit-appearance: none;  
}
@include media-lg{
  .productselection{
    padding: 0px;
  }
  .productTitle {
    font-size: 30px;
    line-height: 150%;
    font-weight: 800;
  }
  .productBox {
    margin: 40px 0px 16px 24px;
    overflow: auto;
  }
  
  .productItem {
    margin-right: 40px;
    font-size: 16px;
    color: #626870;
    padding-bottom: 8px;
    cursor: pointer;
  }

}
@include media-md {
  .productTitle {
    font-size: 20px;
    line-height: 150%;
    margin-left: 24px;
    font-weight: 700;
  }
  .productBox {
    margin: 40px 0px 16px 24px;
    overflow: auto;
  }
  
  .productItem {
    margin-right: 40px;
    font-size: 16px;
    color: #626870;
    padding-bottom: 8px;
    cursor: pointer;
  }
  .arrowBackground{
    display: none;
  }
  .productselection{
    margin-right: 16px;
    padding: 0px;
  }
  .gradientBox{
    height:88px;
    width:88px
  }
  .text_block {
    position: absolute;
    bottom: 16px;
    font-size: 16px;
    font-weight: 700;
    color: white;
  }
}
::-webkit-scrollbar { display: none;}