@import "./_colors";
@import "./_vars";
@import "./_buttons";
@import "../styles/mixins";
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800;900&display=swap");
//@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700&family=Poppins:wght@400;500;700;800&family=Work+Sans:wght@400;500&display=swap');
$font-heading: "Mulish", sans-serif;
$font-content: "Mulish", sans-serif;

* {
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: $font-content;
}

button {
  font-size: 16px;
  line-height: 19px;
  font-style: normal;
  font-weight: 700;

  border: white;
  color: white;
  cursor: pointer;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 4px;
  background: #0067e2;
  // margin-top:42px;
  // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;

  @include media-md {
    width: 218px;
    height: 48px;
    gap: 4px;
    align-items: center;
    // margin-top:32px;
  }

  @include media-sm {
    width: 218px;
    height: 48px;
    align-items: center;
  }
}

body {
  overflow-x: hidden;
  font-size: 14px;
  line-height: 19px;
  font-style: normal;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

.bg-white {
  background: var(--fill-white);
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.text-content {
  font-family: "Mulish", sans-serif;
}

.heading-style {
  font-family: "Mulish", sans-serif;
}

.h-full {
  height: 100%;
}

.text-white {
  color: var(--fill-white);
}

.f-w-bold {
  font-weight: bold;
}

.p-15 {
  padding: 15px;
}

.mb-13 {
  margin-bottom: 13px;
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-1 {
  flex: 1;
}

.style-italic {
  font-style: italic;
}

.section-heading {
  font-family: "Mulish", sans-serif;
  font-size: 44px;
  line-height: 56px;
  font-weight: 800;
}

.section-description {
  font-size: 16.5px;
  line-height: 24px;
}

@include media-md {
  .section-heading {
    font-size: 30px;
    line-height: 36px;
  }

  .section-description {
    font-size: 14px;
    line-height: 22px;
  }
}
.card {
  border: 1px solid #f2f2f2;
  box-shadow: 0px 4px 11px rgba(0, 103, 226, 0.1);
  border-radius: 8px;
  background: var(--fill-white);
  padding: 24px;
  box-sizing: border-box;

  @include media-md {
    padding: 24px 14px;
  }
}
