@import "../styles/mixins";

.btn {
  padding: 10px 16px;
  border-radius: 4px;
  border: none;
  font-weight: bold;
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
  }

  &-primary {
    background-color: var(--fill-primary);
    color: white;

    &:disabled {
      opacity: 0.5;
    }
  }

  &-img {
    padding: 0.5rem;
    display: flex;
    align-items: center;
  }

  &-link {
    color: var(--fill-primary);
    background-color: transparent;
    padding: 0;
    font-weight: 600;
  }

  @include media-md {
    padding: 8px 12px;
    font-size: 12px;
    line-height: 16px;

    &-link {
      padding: 0;
    }
  }
}
