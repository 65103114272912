@import "../../assets/styles/mixins";

.product {
  padding: 0px 9.25% 144px 14.25%;
  .main-text {
    @include flex(column, center, center);
    padding-bottom: 56px;
    .heading {
      font-weight: 800;
      font-size: 40px;
      line-height: 55px;
      padding-bottom: 8px;
    }
    .para {
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      max-width: 648px;
      text-align: center;
    }
  }
  .slider {
    .slick-arrow {
      z-index: 10;
      height: 40px;
      width: 40px;
    }
    .slick-prev {
      left: -110px;
    }
    .slick-next {
      right: 0px;
    }
    .slick-disabled{
      opacity: 0.5;
    }
    .slick-dots {
      bottom: -60px;
      li button::before {
        font-size: 10px;
        color: var(--fill-light-gray-2);
      }
      .slick-active button::before {
        opacity: 1;
        color: #7b61ff;
        font-size: 9px;
        outline: 3px solid #7b61ff;
        border-radius: 50%;
        outline-offset: -2px;
        line-height: 21px;
      }
    }
  }
}
@media (max-width: 1450px){
  .product{
    .slider{
      .slick-next{
        right: -32px;
      }
    }
  }
}
@include media-xl {
  .product {
    padding: 0px 6.25% 144px 9.25%;
  }
}
@include media-md {
  .product {
    padding: 64px 6.25% 100px 6.25%;
  }
}
@include media-sm {
  .product {
    padding: 64px 6.25% 64px 6.25%;
    .main-text {
      .heading {
        font-size: 30px;
        line-height: 36px;
        padding-bottom: 40px;
      }
      .para {
        text-align: left !important;
        font-size: 16px;
        line-height: 21px;
      }
    }
    .slider {
      padding-left: 0px;
      .slick-dots {
        bottom: -25px;
      }
    }
  }
}
