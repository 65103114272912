@import "../../assets/styles/mixins";

.privacy-policy {
    padding: 92px 11.25% 20px;
    .heading {
        font-size: 24px;
        font-weight: 700;
        padding: 20px 0px;
    }
    .pl-16 {
        padding-left: 64px;
    }
}