@import "../../assets/styles/mixins";

.brandSection {
  display: flex;
  flex-direction: row;
  height: 56vh;
  padding: 50px 5.65%;
  justify-content: space-between;
  align-items: center;
  background-color: #f2f8ff;
  margin-top: 82px;
  margin-bottom: 82px;
  
  @include media-lg {
    flex-direction: column;
    height: auto;
    margin-bottom: 0px;
    margin-top:0px;
  }
}
