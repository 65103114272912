@import "../../assets/styles/mixins";
.pride {
  padding: 64px 11.25%;
  background-color: #f6f7f9;
  color: #333340;
  width: 100%;
  .heading {
    @include font(44px, 52px, 800, var(--fill-black));
    font-family: var(--font-heading);
    padding-bottom: 64px;
    color: #000126;
  }
  &__wrapper {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 40px;
    grid-column-gap: 0px;
  }
}
@include media-xl {
  .pride {
    &__wrapper {
      grid-column-gap: 20px;
      grid-row-gap: 20px;
    }
  }
}
@include media-lg {
  .pride {
    .heading {
      font-size: 38px;
      padding: 11.25%;
      text-align: center;
    }
    &__wrapper {
      display: grid;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 20px;
      grid-column-gap: 0px;
    }
  }
}
@include media-md {
  .pride {
    padding: 0px;
    .heading {
      font-size: 28px;
      padding: 0px;
      margin-bottom: 30px;
      text-align: center;
      padding: 56px 0 0 0;
      span {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
      }
    }
    &__wrapper {
      display: grid;
      padding: 42px;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 20px;
     
    }
  }
}
@include media-sm {
  .pride {
    padding: 0px;
    .heading {
      padding: 56px 0 0 0;
      font-size: 28px;
      text-align: center;
      line-height: 37.5px;
      span {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
      }
    }
    &__wrapper {
      display: grid;
      place-items: center;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
 
    }
  }
}
