@import "../../assets/styles/mixins";

.life {
  padding: 64px 11.25%;
  width: 100%;
  @include flex(row, space-between, center);
  &__left {
    display: flex;
    flex-direction: column;
    .heading {
      font-size: 44px;
      font-weight: 800;
      line-height: 52px;
    }
    .para {
      font-size: 16px;
      font-weight: 500;
      line-height: 22.4px;
      width: 62%;
    }
  }
  &__right-images {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .images {
      display: flex;
      gap: 16px;
      .highlight {
        width: 240px;
      }
      .plain {
        width: 152px;
      }
    }
  }
}
@include media-lg {
  .life {
    @include flex(column, center, center);
    gap: 40px;
    &__left {
      align-items: center;
      .heading {
        text-align: center;
      }
      .para {
        width: 100%;
      }
    }
  }
}
@include media-md {
  .life {
    @include flex(column, center, center);
    gap: 40px;
    &__left {
      align-items: center;
      .heading {
        text-align: center;
      }
      .para {
        width: 100%;
        line-height: 148%;
      }
    }
    &__right-images {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .images {
        display: flex;
        gap: 8px;
        width: 100%;
        justify-content: center;

        .highlight {
          width: 240px;
          border-radius: 20px;
        }
        .plain {
          width: 150px;
          border-radius: 20px;
        }
      }
    }
  }
}
@include media-sm {
  .life {
    padding: 35px 6.25% 78px 6.25%;
    @include flex(column, center, center);
    gap: 40px;
    &__left {
      .heading {
        text-align: center;
        font-size: 30px;
        line-height: 37.65px;
        margin: 0px;
      }
      .para {
        font-size: 14px;
        width: 100%;
      }
    }
    &__right-images {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .images {
        display: flex;
        gap: 8px;
        width: 100%;
        justify-content: center;

        .highlight {
          width: 183px;
          border-radius: 20px;
        }
        .plain {
          width: 110px;
          border-radius: 20px;
        }
      }
    }
  }
}
