@import "../../../assets/styles/mixins";

.aboutbanner {
  height: 20vh;
  padding: 22px 11.25%;
  width: 100%;
  @include flex(row, space-between, center);
  background-image: url("../../../assets/images/banner/design.svg"),
    linear-gradient(269.01deg, #cee4ff 51.32%, rgba(206, 228, 255, 0) 100%);
  background-repeat: no-repeat;
  background-position: right;
  &__left {
    @include flex(row, center, center);
    gap: 35px;
    img {
      width: 100px;
      height: 100px;
    }
    .para {
      font-family: Mulish;
      @include font(24px, 34px, 700, #333340);
    }
  }
  &__right {
    @include flex(row, center, center);
    .btn {
      width: 180px;
      border-radius: 8px;
    }
  }
}
@include media-md {
  .aboutbanner {
    height: 35vh;
    background-position: -18% 46%;
    background-image: url("../../../assets/images/banner/design.svg"),
      linear-gradient(134.07deg, #cee4ff 4.09%, rgba(206, 228, 255, 0) 98.57%);
    @include flex(column, center, center);
    padding-bottom: 56px;
    &__left {
      @include flex(column, center, center);
      gap: 24px;
      padding-left: 0px;
      .para {
        font-size: 24px;
        text-align: center;
      }
      img {
        width: 150px;
        height: 150px;
      }
    }
    &__right {
      @include flex(column, center, center);
      .btn {
        height: 40px;
        width: 219px;
        font-size: 16px;
      }
    }
  }
}
@include media-sm {
  .aboutbanner {
    background-position: -22% 90%;

    background-image: url("../../../assets/images/banner/design.svg"),
      linear-gradient(134.07deg, #cee4ff 4.09%, rgba(206, 228, 255, 0) 98.57%);
    @include flex(column, center, center);
    padding-bottom: 56px;
    &__left {
      @include flex(column, center, center);
      gap: 24px;
      padding-left: 0px;
      .para {
        font-size: 24px;
        text-align: center;
      }
      img {
        width: 150px;
        height: 150px;
      }
    }
    &__right {
      @include flex(column, center, center);
    }
  }
  @media (max-width: 400px) {
    .aboutbanner {
      background-position: -57% 147%;
    }
  }
}
