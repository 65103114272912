@import "../../assets/styles/mixins";

.source {
  padding: 144px 11.25%;
  @include flex(row, space-between, center);
  gap: 72px;
  &__left {
    background: var(--fill-orange-shade);
    border-radius: 16px;
    padding: 23px;
    filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.06));
    height: 369px;
    img {
      width: 100%;
      height: 324px;
    }
  }
  &__right {
    @include flex(column, center, flex-start);
    .heading {
      font-size: 40px;
      font-weight: 800;
      max-width: 500px;
      line-height: 48px;
    }
    .para {
      max-width: 470px;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      padding-top: 32px;
      padding-bottom: 32px;
    }
    .btn {
      font-size: 18px;
      height: 56px;
    }
    .count {
      display: flex;
      gap: 64px;
      .col {
        display: flex;
        flex-direction: column;
        .number {
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
        }
        .name {
          font-weight: 400;
          font-size: 16px;
          line-height: 32px;
        }
      }
      padding-bottom: 32px;
    }
    .btn {
      width: 312px;
      border-radius: 8px;
    }
  }
}
@media (max-width: 1400px) {
  .source{
    &__left{
      height: auto;
      img{
        height: auto;
      }
    }
  }
}
@include media-xl {
  .source {
    flex-direction: column;
    gap: 48px;
  }
}
@include media-xl {
  .source {
    &__right {
      align-items: center;
      .heading {
        text-align: center;
      }
      .para {
        text-align: center;
      }
    }
  }
}
@include media-sm {
  .source {
    padding: 64px 6.25% 64px 6.25%;
    background-color: var(--fill-blue);
    &__left {
      padding: 12.84px;
    }
    &__right {
      align-items: center;
      .heading {
        font-size: 30px;
        line-height: 36px;
        text-align: left;
        max-width: 310px;
      }
      .para {
        font-size: 16px;
        text-align: left;
        max-width: 312px;
      }
      .count {
        gap: 40px;
        .col {
          .number {
            font-size: 20px;
          }
          .name {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }
  }
}
@media (min-width: 300px) and (max-width: 350px) {
  .source {
    &__right {
      .btn {
        max-width: 285px;
      }
    }
  }
}
