@import "../../../assets/styles/mixins";

.supplierProfileHero {
  height: 284px;
  object-fit: cover;

  @include media-md {
    height: 32vh;
  }
}
