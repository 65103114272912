@import "../../assets/styles/mixins";
.support {
  padding: 144px 11.25%;
  @include flex(column, center, center);
  .heading {
    font-weight: 800;
    font-size: 34px;
    max-width: 550px;
    line-height: 40px;
    text-align: center;
    padding-bottom: 6px;
  }
  p {
    max-width: 515px;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
  }
  &__options {
    padding-top: 78px;
    display: flex;
    gap: 72px;
    .box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 340px;
      height: 84px;
      background: rgba(225, 225, 234, 0.2);
      border-radius: 16px;
      img {
        width: 55px;
        height: 56px;
        margin: 1rem;
      }
      .box2 {
        .agenda {
          font-weight: 400;
          font-size: 16px;
          line-height: 27px;
          color: var(--fill-neutral-gray);
        }
        .help {
          font-weight: 700;
          font-size: 18px;
          line-height: 27px;
          color: var(--fill-neutal-black);
        }
      }
    }
  }
}
@include media-md {
  .support {
    &__options {
      flex-direction: column;
      gap: 32px;
    }
  }
}
@include media-sm {
  .support {
    padding: 64px 6.25%;
    .heading {
      text-align: left;
      font-size: 29px;
      max-width: 310px;
      line-height: 36px;
      padding-bottom: 25px;
    }
    p {
      text-align: left;
      font-size: 15px;
      max-width: 312px;
      line-height: 22px;
    }
    align-items: flex-start;
    &__options {
      padding-top: 33px;
      flex-direction: column;
      gap: 24px;
    }
    .box {
      width: 312px;
    }
  }
}
@media (min-width: 300px) and (max-width: 350px){
  .support{
    .box{
      max-width: 280px;
      .box2{
        .agenda{
          font-size: 0.875rem;
        }
        .help{
          font-size: 1rem;
        }
      }
    }
  }
}