:root {
  --fill-black: #000126;
  --fill-black-t-90: #00012690;
  --fill-white: #ffffff;
  --fill-gray: #f2f2f2;
  --fill-red: #eb5757;
  --fill-dark-gray-3: #676773;
  --fill-dark-gray-2: #333340;
  --fill-dark-gray: #93939f;
  --fill-dark-gray-1: #f4f5f7;
  --fill-light-gray: #e5e5e5;
  --fill-neutral-gray: #303c4a;
  --fill-neutral-gray2: #626870;
  --fill-neutral-black: #00142d;
  --fill-light-gray-2: #d4d4d4;
  --fill-deep-gray: #f2f4f7;
  --fill-deep-gray2: #f6f7f9;
  --fill-dark-blue: #020288;
  --fill-deep-blue: #1d3557;
  --fill-blue: #f9faff;
  --fill-blue-primary-2: #f2f8ff;
  --fill-blue-grey: #7084a1;
  --fill-sky-blue: #f6f7f9;
  --fill-blue-light: #f4f9ff;
  --fill-turquoise: #2ec5ce;
  --fill-turquoise-1: #56ccf2;
  --fill-blue-1: #0067e2;
  --fill-blue-2: #153a81;
  --fill-gray-1: #18191f;
  --fill-footer: #f2f8ff;
  --fill-blue-secondary: #f2f8ff;
  --fill-green-secondary: #edfef4;
  --fill-neutral-green: #27ae60;
  --fill-purple-secondary: #fdf6ff;
  --fill-blue-secondary-1: #e4f1ff;
  --fill-green-secondary-1: #e4ffef;
  --fill-purple-secondary-1: #fbeeff;
  --fill-light-orange: #ffeedf;
  --fill-orange: #dd750e;
  --fill-orange-shade: #ffffff;
  --fill-primary-orange: #fffaf5;
  --fill-primary: var(--fill-blue-1);
  --fill-primary2: #cee4ff;
  --fill-yellow-light: #fffee5;
  --fill-neutral-black: #00142d;
  --fill-primary-teal: #007180;
  --fill-neutal-gray: #303c4a;
  --fill-neutal-gray2: #626870;
}
