@import "../../assets/styles/mixins";

.mainbox {
  display: flex;
}
  .inner1 {
    background-color: #fdf6ff;
    background-image: url("../../assets/images/hero/rolls.png");
    background-size: 280px;
    background-repeat: no-repeat;
    background-position: right bottom;
    padding: 50px 5.65% 80px 5.65%;
    width: 50%;
    .heading {
      @include flex(row, flex-start, center);
      @include font(30px, 42px, 800, var(--fill-black));
      margin-bottom: 32px;
      max-width: 400px;
    }
    .description {
      @include flex(row, center, flex-start);
      @include font(18px, 27px, 400, var(--fill-neutal-gray));
      max-width: 443px;
      margin-bottom: 8%;
      max-width: 450px;
    }
  }
  .inner2 {
    background-color: #ebfdff;
    padding: 50px 5.65% 80px 5.65%;
    background-image: url("../../assets/images/hero/supplieer.png");
    background-size: 260px;
    background-repeat: no-repeat;
    background-position: right bottom;
    width: 50%;
    .heading {
      @include flex(row, flex-start, center);
      @include font(30px, 42px, 800, var(--fill-black));
      margin-bottom: 32px;
      max-width: 380px;
    }
    .description {
      @include flex(row, center, flex-start);
      @include font(18px, 27px, 400, var(--fill-neutal-gray));
      max-width: 443px;
      margin-bottom: 8%;
      max-width: 420px;
    }
  }
  .sectionButtontxt{
    font-weight: 700;
  }
  .section_button {
    padding: 16px 80px;
    width:auto
  }
  .arrow {
    margin-top: 4px;
    margin-left: 12px;
    @include media-md{
      margin-left: 4px
    }
  }

@include media-lg {
  .mainbox {
    @include flex(column, center, center);
    padding:0;
  align-items: normal;
  }
  .inner1{
    width: 100%;
    
  }
  .inner2{
    width: 100%;
  
  }
 
  
}
@include media-md {
  .mainbox {
    @include flex(column, center, center);
    padding:0;
  align-items: normal;
  }
  .inner1{
    width: 100%;
    .heading{
      @include font(20px, 24px, 800, var(--fill-black));
      margin-bottom: 24px;
      max-width: 300px;
    }
    .description {
      @include font(12px, 20px, 400, var(--fill-neutal-gray));
      max-width: 270px !important;
      margin-bottom: 8%;
    }
  }
  .inner2{
    width: 100%;
    .heading{
      @include font(20px, 24px, 800, var(--fill-black));
      margin-bottom: 24px;
      max-width: 300px;
    }
    .description {
      @include font(12px, 20px, 400, var(--fill-neutal-gray));
      max-width: 240px !important;
      margin-bottom: 8%;
    }
  }
 
  
}
@include media-sm;
@include media-md {
  .mainbox {
    padding: 0;
    @include flex(column, center, center);
    &__inner1 {
      @include flex(column, center, center);

      .heading {
        @include font(30px, 37.65px, 800, var(--fill-black));
        margin-bottom: 0px;
        margin-top: 0px;
      }
      .description {
        @include font(14px, 148%, 400, var(--fill-dark-gray-2));
        text-align: left;
        margin-bottom: 32px;
      }
    }
    &__inner2 {
      .m11,
      .m22 {
        .m1,
        .m2,
        .m3,
        .m4 {
          width: 152px;
          height: 144px;
          margin: 2.5px;
        }
      }
    }
    #b1 {
      display: none;
    }
    #b2 {
      display: block;
      margin-top: 42px;
      display: flex;
      gap: 4px;
    }
  }
  .inner1{
    padding: 32px 24px;
    background-size: 170px;
    .description{
      max-width: 350px;
    }
  }
  .inner2{
    padding: 32px 24px;
    background-size: 170px;
    .description{
      max-width: 350px;
    }
  }
  .section_button {
    padding: 16px ;
    width:auto;

  }
}
@include media-md {
  .mainbox {
    padding: 0;
    @include flex(column, center, center);
    &__inner1 {
      @include flex(column, center, center);

      .heading {
        @include font(30px, 37.65px, 800, var(--fill-black));
        margin-bottom: 0px;
        margin-top: 0px;
      }
      .description {
        @include font(16px, 148%, 500, var(--fill-dark-gray-2));
        text-align: left;
        margin-bottom: 32px;
      }
    }
  }
}

@include media-md {
  .mainbox {
    &__inner1 {
      .description {
        @include font(14px, 148%, 500, var(--fill-dark-gray-2));
        text-align: left;
        margin-bottom: 32px;
      }
    }
    &__inner2 {
      .m11 {
        width: 328px;
      }
    }
  }
}
