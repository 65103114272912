@import "../../../assets/styles/mixins";

.adwordsFormContainer {
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  .nudge {
    @include flex(row, center, center);
  }
  &__header {
    text-align: center;
    display: flex;
    justify-content: space-between;
    a {
      margin-bottom: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .heading {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      padding-bottom: 24px;
      text-align: left;
    }
  }
  .formContent {
    display: block !important;
    height: 52vh;
    overflow-y: auto;
    span {
      padding-bottom: 8px;
      display: inline-block;
      line-height: 20px;
      color: var(--fill-dark-gray-2);
    }
    @include media-md{
      height: 60vh;
    }
  }
  .formContent::-webkit-scrollbar {
    display: none;
  }
  .formContent {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .form {
    background-color: var(--fill-white);
    border: none;
    margin-top: 0;
    border-radius: 0;
    padding: 24px 24px 16px 24px;
    .submit {
      @include media-md {
        &__container {
          padding-top: 8px;
          box-shadow: inset 0px 1px 0px rgba(0, 1, 38, 0.1);
        }
      }
    }
    .field {
      position: relative;
      span,
      label {
        color: var(--fill-dark-gray-3);
        font-size: 12px;
        line-height: 16px;
      }

      .field-error p {
        font-size: 12px;
        line-height: 16px;
      }
    }
    .flex {
      display: flex;
    }
    button[type="submit"] {
      width: 100%;
      border-radius: 8px;
      height: 40px;
    }
    input {
      padding: 12px 16px;
      font-size: 14px;
      font-weight: 400;
    }
    .controls {
      box-shadow: 0px 0px 3px rgba(0, 103, 226, 0.12);
      padding: 10px 4px;
      background-color: var(--fill-white);
      svg {
        pointer-events: none;
        path {
          fill: var(--fill-dark-gray-3);
        }
      }

      &.leftCTA {
        position: absolute;
        left: 0.5px;
        top: 25px;
        height: 40px;
        background-color: var(--fill-white);
        svg {
          transform: rotate(180deg);
        }
        @include media-md {
          width: 32px;
          height: 40px;
          top: 9px;
          left: -1px;
        }
      }

      &.rightCTA {
        position: absolute;
        right: 1px;
        top: 25px;
        height: 40px;
        background-color: var(--fill-white);
        @include media-md {
          width: 32px;
          height: 40px;
          top: 9px;
          right: -1px;
        }
      }
    }
    @include media-md {
      padding: 32px 0px;
    }
  }

  .phoneInput {
    border: 1px solid var(--fill-light-gray-2);
    border-radius: 8px;
    display: flex;
    height: 46px;

    input {
      height: 44px;
      width: 58.33%;
      padding-left: 4px;
      padding-right: 4px;
      line-height: 1.25px;
      border: none;
    }
    input:focus {
      outline: none;
      border: none;
    }
    .object-fill {
      height: 44px;
      background: rgba(225, 225, 234, 0.2);
      width: 67px;
      border-radius: 7px 0px 0px 7px;
      padding: 11px 17px;
    }
    .flag {
      height: 44px;
      display: flex;
      align-items: center;
      padding: 12px;
    }
  }
  .phoneInput:focus {
    border: 1px solid var(--fill-blue-1);
  }
  .checkbox-group {
    overflow-x: hidden;

    span {
      padding: 10px 12px !important;
      margin-right: 4px !important;
      font-weight: normal !important;
      white-space: nowrap !important;
    }

    input[type="checkbox"]:checked + span:before,
    input[type="radio"]:checked + span:before {
      top: 12px !important;
    }
    .radioButton,
    .checkboxButton {
      input[type="radio"],
      input[type="checkbox"] {
        display: none;
      }

      input[type="radio"] + span,
      input[type="checkbox"] + span {
        border: 1px solid #e5e5e5;
        border-radius: 8px;
        color: var(--fill-neutral-gray);
        display: block;
        font-size: 14px;
        font-weight: normal;
        height: auto;
        margin-right: 6px !important;
        padding: 10px 12px !important;
        white-space: nowrap;
      }

      input[type="radio"]:checked + span,
      input[type="checkbox"]:checked + span {
        background: #f2f8ff;
        color: #0067e2;
        font-weight: 600 !important;
        padding-left: 28px !important;
        position: relative;
        border: none;

        &::before {
          background: url("../../../assets/images/CheckBlue.svg");
          content: "";
          height: 12px;
          left: 10px;
          position: absolute;
          top: 14px;
          width: 12px;
        }
      }

      input[type="radio"]:disabled + span,
      input[type="checkbox"]:disabled + span {
        opacity: 0.5;
      }

      &.withoutTick {
        input[type="radio"]:checked + span,
        input[type="checkbox"]:checked + span {
          padding-left: 12px;

          &::before {
            content: "";
            height: 0;
            width: 0;
          }
        }
      }
    }
  }

  .form .field-contact {
    > label > div {
      position: relative;
    }
  }

  &__form_submitted {
    height: 552px;

    background: var(--fill-white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 40px;

    svg {
      transform: scale(0.7);
    }

    .heading {
      font-size: 18px;
      font-weight: bold;
      color: var(--fill-dark-gray-2);
      margin-top: 16px;
      padding-bottom: 8px;
    }

    .subheading {
      color: var(--fill-dark-gray-3);
      text-align: center;

      .bold {
        font-weight: bold;
        color: var(--fill-black);
      }
    }
  }
}

// Note: To be Refactored

.field {
  .checkbox-group {
    transition: margin 1s ease-in-out !important;
    &.right {
      margin-left: -100px;
    }

    &.left {
      margin-left: 0;
    }
  }
}
.cross {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 4px;
  background-color: white;
  width: 100%;
  height: 40px;
  color: var(--fill-blue-1);
  border-radius: 8px;
}
