@import "../../assets/styles/mixins";

  .variety-section {
    background: rgba(225, 225, 234, 0.2);
    padding: 56px 11.25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .center-text {
      @include flex(column, center, center);
      .heading {
        font-weight: 800;
        font-size: 40px;
        line-height: 55px;
        text-align: center;
        padding-bottom: 8px;
      }
      .para {
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        max-width: 648px;
        color: var(--fill-neutal-gray);
        padding-bottom: 24px;
      }
    }
    .mood-image-section {
      padding: 40px 48px;
      border-radius: 16px;
      cursor: pointer;
      .name {
        font-weight: 600;
        font-size: 26px;
        line-height: 120%;
        color: var(--fill-neutal-gray);
        padding-bottom: 32px;
        display: flex;
        .arrow{
          visibility: hidden;
        }
      }
      .image-box {
        display: flex;
        justify-content: space-between;
        .special{
          width: 412px;
        }
        &__column {
          display: flex;
          flex-direction: column;
          gap: 32px;
          .row {
            display: flex;
            gap: 32px;
            img {
              height: 144px;
              width: 124px;
              border-radius: 8px;
            }
          }
        }
      }
    }
    .mood-image-section:hover{
      background-color: white;
      box-shadow: 0px 0px 3px rgba(0, 103, 226, 0.12);
      .name{
        font-weight: 800;
        .arrow{
          visibility: visible;
        }
      }
    }
  }
// }
@media (min-width: 1200px) and (max-width: 1350px) {
  .variety-section {
      .mood-image-section {
        .name {
          justify-content: center;
        }
        .image-box {
          flex-direction: column;
          align-items: center;
          gap: 48px;
          &__column {
            align-items: center;
          }
        }
      }
    }
  }
@media (min-width: 1330px) and (max-width: 1460px){
  .variety-section{
      padding: 56px 8.5%;
  }
}
@include media-xl {
  .variety-section{
    padding: 56px 21px;
      .mood-image-section {
        .name {
          justify-content: center;
        }
        .image-box {
          flex-direction: column;
          align-items: center;
          gap: 48px;
          &__column {
            align-items: center;
          }
        }
    }
  }
}
@include media-lg {
  .variety-section {
      .mood-image-section {
        .image-box {
          flex-direction: column;
          &__column {
            .row {
              img {
                width: 65px;
                height: 75px;
              }
            }
          }
        }
      }
    }
}
@include media-sm {
  .variety-section {
    border-radius: 0px;
      .center-text {
        align-items: flex-start;
        .heading {
          font-weight: 800;
          font-size: 30px;
          line-height: 36px;
          text-align: left;
          padding-bottom: 40px;
        }
        .para {
          font-weight: 400;
          font-size: 16px;
          line-height: 21px;
          text-align: left;
        }
      }
      .mood-image-section {
        padding: 56px 0px 0px 0px;
        .name {
          justify-content: flex-start;
          font-size: 23px;
        }
        .image-box {
          display: block;
          .special {
            width: 100%;
            height: auto;
            padding-bottom: 24px;
          }
          &__column {
            display: block;
            .row {
              display: flex;
              justify-content: space-between;
              gap: 0px;
              padding-bottom: 16px;
            }
          }
        }
      }
      .mood-image-section:hover{
        background-color: transparent;
        box-shadow: none;
        .name{
          font-weight: 600;
          .arrow{
            visibility: hidden;
          }
        }
      }
    }
  }
