@import "../../assets/styles/mixins";

.textile {
  background-color: #f2f2f2;
  @include flex(row, space-between, flex-start);

  padding-right: 11.25%;

  &__mapWrapper {
    padding-right: 17%;
    img {
      height: auto;
      width: 100%;
    }
  }
  &__contentWrapper {
    padding-top: 56px;
    width: 432px;
    .content {
      &__heading {
        @include font(44px, 52px, 800, var(--fill-black));
        font-family: var(--font-heading);
        margin-bottom: 16px;
      }
      &__description {
        @include font(16px, 22.4px, 500, var(--fill-dark-gray-2));
        margin-bottom: 15%;
      }
    }
  }
}

@include media-lg {
  .textile {
    padding: 4.375% 11.25% 7.1875% 0;
    @include flex(row, flex-start, flex-start);
    width: 100vw;
    &__mapWrapper {
      height: auto;
    }
    &__contentWrapper {
      margin: 0px;
      padding-top: 0px;
      .content {
        &__heading {
          @include font(30px, 40.92px, 800, var(--fill-black));
          text-align: left;
        }
        &__description {
          @include font(14px, 22px, 400, var(--fill-dark-gray-2));
          margin: 0px;
          width: 100%;
          margin-bottom: 0%;
          text-align: left;
        }
      }
    }
  }
}
@include media-md {
  .textile {
    padding: 4.375% 6.25% 15px 0;
    @include flex(column, center, center);
    &__mapWrapper {
      padding-right: 25%;
    }
    .content {
      &__heading {
        @include font(30px, 37.65px, 800, var(--fill-black));
        text-align: center;
      }
      &__description {
        @include font(14px, 148%, 400, var(--fill-dark-gray-2));
        margin: 0px;
        width: 100%;
        margin-bottom: 56px;
        text-align: left;
      }
    }
  }
}
@include media-sm {
  .textile {
    padding: 4.375% 6.25% 15px 0;
    @include flex(column, center, center);
    &__contentWrapper {
      padding-top: 31px;
      width: 320px;
      padding-left: 6.25%;

      .content {
        &__heading {
          @include font(30px, 37.65px, 800, var(--fill-black));
          text-align: center;
        }
        &__description {
          @include font(14px, 148%, 400, var(--fill-dark-gray-2));
          margin: 0px;
          width: 100%;
          margin-bottom: 56px;
          text-align: left;
        }
      }
    }
  }
}
