@import "../../assets/styles/mixins";

.certificates {
  padding: 72px;
  background-color: var(--fill-sky-blue);
  &__heading {
    @include flex(row, center, center);
    @include font(32px, 28px, 800, #000126);
    width: 55%;
    text-align: center;
    padding-bottom: 56px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    .certif {
      background-color: white;
      height: 86px;
      width: 124px;
      border-radius: 16px;
      text-align: center;
      margin-right: 32px;
      margin-bottom: 16px;
      border: 1px solid #ddf0ff;
      img {
        width: 100%;
        height: auto;
        padding: 14px 8px;
      }
    }
  }
  .mobile {
    display: none;
  }
}
@media (max-width: 1300px) {
  .certificates {
    &__heading {
      justify-content: flex-start;
    }
    &__list {
      max-width: 600px;
    }
  }
}
@include media-lg{
  .certificates{
    &__list{
      max-width: 400px;
    }
  }
}
@include media-sm;
@include media-md {
  .certificates {
    padding: 36px 24px 48px 24px;
    @include media-sm {
      padding-left: 20px;
      padding-right: 20px;
    }
    &__heading {
      @include font(30px, 40px, 800, #000126);
      padding-bottom: 40px;
      justify-content: center;
    }
    &__list {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      gap: 16px;
      max-width: none;
      .certif {
        background-color: white;
        height: 60px;
        width: 96px;
        display: flex;
        border-radius: 8px;
        text-align: center;
        margin-right: 0px;
        margin-bottom: 8px;
        border: 1px solid #ddf0ff;
        img {
          height: auto;
          width: 100%;
          padding: 0px;
          @include media-sm {
            padding: 8px;
          }
        }
      }
    }
    .mobile {
      display: flex;
    }
    .desktop {
      display: none;
    }
  }
}
