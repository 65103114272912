@import "../../assets/styles/mixins";

.all-blogs-page {
  color: #333340;
  font-size: 14px;
  line-height: 140%;
  width: 100%;
  padding: 0 5.65% 32px 5.65%;
  .main-heading {
    padding-top: 3rem;
    h2 {
      font-weight: 800;
    }
  }
  .blogs_wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 40px;
    margin-top: 32px;
    @include media-md{
      grid-template-columns: 1fr 1fr;
    }
    @include media-sm{
      grid-template-columns: 1fr;
    }
   }
  .blogsText {
    @include font(14px, 20px, 500, var(--fill-black));
  }
}