@import "../../assets/styles/mixins";

.hero-suppliers {
  position: relative;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  object-fit: fill;
  background-image: url("../../assets/images/hero/hero-suppliers.webp");
  background-repeat: no-repeat;
  background-size: cover;
  .hero {
    &__content {
      .hero__summary,
      .hero__summary > p {
        max-width: 892px;
      }
      &__action {
        font-weight: 700;
        font-size: 20px;
        padding: 16px 24px;
        line-height: 24px;
        border-radius: 8px;
        margin-top: 60px;
      }
      .scroll-down {
        position: absolute;
        left: 50%;
        bottom: 5%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  @include media-md {
    object-fit: cover;
    background-position: center;

    .hero {
      &__heading {
        line-height: 115%;
      }
      &__content {
        .hero__summary p {
          font-size: 14px !important;
          line-height: 132%;
          height: auto !important;
          max-width: 312px !important;
          padding-bottom: 24px;
        }
        .hero__action {
          font-size: 14px;
          padding: 8px 12px;
          margin-top: 0px;
        }

        .scroll-down {
          display: none;
        }
      }
    }
  }
}
.hero-wrapper {
  position: relative;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  object-fit: fill;
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.6) 100%
    );
  background-repeat: no-repeat;
  background-size: cover;

  background-color: var(--fill-black-t-90);

  .hero {
    &__heading {
      line-height: 88px;
    }

    &__content {
      padding-top: 16px;

      .hero__summary p {
        font-size: 20px;
        line-height: 28px;
        max-width: 736px;
      }
      .scroll-down {
        position: absolute;
        left: 45%;
        bottom: 5%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__action {
      font-weight: 700;
      font-size: 20px;
      padding: 16px 24px;
      line-height: 24px;
      border-radius: 8px;
      margin-top: 60px;
    }
  }
  @include media-md {
    height: 70vh;
    object-fit: cover;
    background-position: center;

    .hero {
      &__heading {
        line-height: 40px;
      }
      &__content {
        .hero__summary p {
          font-size: 14px !important;
          line-height: 20px;
          height: auto !important;
          max-width: 312px !important;
          padding-bottom: 24px;
        }
        .hero__action {
          font-size: 14px;
          padding: 8px 12px;
          margin-top: 16px;
        }

        .scroll-down {
          display: none;
        }
      }
    }
  }
}
