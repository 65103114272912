@import "../../assets/styles/mixins";

.pillars {
  padding: 80px 11.25%;
  background-color: var(--fill-green-secondary);
  display: flex;
  justify-content: space-between;
  .info {
    .heading {
      font-size: 34px;
      font-weight: 800;
      line-height: 46px;
      max-width: 400px;
      color: var(--fill-neutral-black);
    }
    .para {
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      max-width: 382px;
      padding-top: 24px;
      color: var(--fill-neutal-gray);
    }
  }
  &__images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 108px;
    grid-column-gap: 98px;
    .column {
      .pillar {
        padding-bottom: 16px;
      }
      .text {
        border-top: 1px solid var(--fill-light-gray-2);
        font-size: 24px;
        font-weight: 400;
        line-height: 28px;
        padding-top: 24px;
        color: var(--fill-neutal-gray);
      }
      .subtext {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        max-width: 212px;
        padding-top: 8px;
        color: var(--fill-neutal-gray);
      }
    }
  }
}
@include media-xl{
  .pillars{
    .info{
      .para{
        max-width: 270px;
      }
    }
  }
}
@include media-lg {
  .pillars {
    flex-direction: column;
    .info {
      padding-bottom: 64px;
      .para{
        max-width: 382px;
      }
    }
  }
}
@include media-sm {
  .pillars {
    padding: 64px 24px;
    .info {
      .heading {
        font-size: 30px;
        line-height: 130%;
      }
      .para {
        font-size: 16px;
        padding-top: 16px;
      }
    }
    &__images{
        grid-gap: 16px;
        place-items: center;
        .column{
            max-width: 150px;
            border: 1px solid var(--fill-light-gray);
            border-radius: 16px;
            padding: 16px;
            min-height: 302px;
            .text{
                font-size: 18px;
                font-weight: 600;
                padding-top: 16px;
            }
            .subtext{
                font-size: 14px;
                line-height: 19px;
            }
        }
        #column0, #column1{
          min-height: 270px;
        }
    }
  }
}
