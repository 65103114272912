@import "../../assets/styles/mixins";
.bdy {
  padding: 140px 11.25% 48px 11.25%;
}
#slider {
  height: 440px;
  margin-bottom: 80px;
  position: relative;
  perspective: 1000px;
  transform-style: preserve-3d;
}

#slider .dots {
  position: absolute;
  bottom: -34px;
}
input[type="radio"] {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  outline: none;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
input[type="radio"]:checked:before {
  background: #0067e2;

}

#s0 {
  left: 47%;
}
#s1 {
  left: 48.5%;
}
#s2 {
  left: 50%;
}
#s3 {
  left: 51.5%;
}
#s4 {
  left: 53%;
}
#slider label {
  margin: auto;
  width: 768px;
  height: 100%;

  position: absolute;
  left: 0;
  right: 0;
  cursor: pointer;
  transition: transform 0.4s ease;
  z-index: -1;
  img {
    border-radius: 12px;
  }
}

#s0:checked ~ #slide3,
#s1:checked ~ #slide4,
#s2:checked ~ #slide0,
#s3:checked ~ #slide1,
#s4:checked ~ #slide2 {
  transform: translate3d(-0%, 0, -500px);
}

#s0:checked ~ #slide4,
#s1:checked ~ #slide0,
#s2:checked ~ #slide1,
#s3:checked ~ #slide2,
#s4:checked ~ #slide3 {
  transform: translate3d(-60%, 0, -500px);
}

#s0:checked ~ #slide0,
#s1:checked ~ #slide1,
#s2:checked ~ #slide2,
#s3:checked ~ #slide3,
#s4:checked ~ #slide4 {
  transform: translate3d(0, 0, 0);
}

#s0:checked ~ #slide1,
#s1:checked ~ #slide2,
#s2:checked ~ #slide3,
#s3:checked ~ #slide4,
#s4:checked ~ #slide0 {
  transform: translate3d(60%, 0, -500px);
}

#s0:checked ~ #slide2,
#s1:checked ~ #slide3,
#s2:checked ~ #slide4,
#s3:checked ~ #slide0,
#s4:checked ~ #slide1 {
  transform: translate3d(0%, 0, -500px);
}
.car_arr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  margin: auto 17%;
  width: 66%;
  top: 43%;
  z-index: 99;
}
.slidearrow {
  background-color: transparent;
  img{
    height: 40px;
    width: 40px;
  }
}

@media (max-width: 1450px) {
  .car_arr {
    margin: auto 9%;
    width: 82%;
  }
}
@include media-xl {
  #slider label {
    margin: auto;
    width: 80%;
    height: 100%;
    cursor: pointer;

    z-index: -1;
  }
  .car_arr {
    margin: auto 9%;
    width: 82%;
  }
  #s0 {
    left: 44%;
  }
  #s1 {
    left: 47%;
  }
  #s2 {
    left: 50%;
  }
  #s3 {
    left: 53%;
  }
  #s4 {
    left: 56%;
  }
}
@include media-lg {
  #slider label {
    margin: auto;
    width: 80%;
    height: 100%;
    border-radius: 4px;
    position: absolute;
    left: 0;
    right: 0;
    cursor: pointer;
    transition: transform 0.4s ease;
    z-index: -1;
  }
  .car_arr {
    margin: auto 9%;
    width: 82%;
  }
  #s0 {
    left: 44%;
  }
  #s1 {
    left: 47%;
  }
  #s2 {
    left: 50%;
  }
  #s3 {
    left: 53%;
  }
  #s4 {
    left: 56%;
  }
}
@include media-md {
  .car_arr {
    margin: auto -3%;
    width: 106%;
  }
}
@include media-sm {
  .bdy {
    padding-bottom: 35px;
    padding-left: 0px;
    padding-right: 0px;
  }
  #slider .dots {
    position: absolute;
    bottom: -95px;
  }

  #s0 {
    left: 44%;
  }
  #s1 {
    left: 47%;
  }
  #s2 {
    left: 50%;
  }
  #s3 {
    left: 53%;
  }
  #s4 {
    left: 56%;
  }
  #slider {
    height: 300px;
  }
  #slider label {
    margin: auto;
    width: 60%;
    height: 271px;
    border-radius: 4px;
    position: absolute;
    left: 0;
    right: 0;
    cursor: pointer;
    transition: transform 0.4s ease;
    z-index: -1;
  }
  .car_arr {
    width: 106%;
  }

  #s0:checked ~ #slide3,
  #s1:checked ~ #slide4,
  #s2:checked ~ #slide0,
  #s3:checked ~ #slide1,
  #s4:checked ~ #slide2 {
    transform: translate3d(-0%, 0, -500px);
  }

  #s0:checked ~ #slide4,
  #s1:checked ~ #slide0,
  #s2:checked ~ #slide1,
  #s3:checked ~ #slide2,
  #s4:checked ~ #slide3 {
    transform: translate3d(0%, 0, -500px);
  }

  #s0:checked ~ #slide0,
  #s1:checked ~ #slide1,
  #s2:checked ~ #slide2,
  #s3:checked ~ #slide3,
  #s4:checked ~ #slide4 {
    transform: translate3d(0, 0, 442px);
  }

  #s0:checked ~ #slide1,
  #s1:checked ~ #slide2,
  #s2:checked ~ #slide3,
  #s3:checked ~ #slide4,
  #s4:checked ~ #slide0 {
    transform: translate3d(0%, 0, -500px);
  }

  #s0:checked ~ #slide2,
  #s1:checked ~ #slide3,
  #s2:checked ~ #slide4,
  #s3:checked ~ #slide0,
  #s4:checked ~ #slide1 {
    transform: translate3d(0%, 0, -500px);
  }
}
