@import "../../assets/styles/mixins";

.appOutlookContainer {
  background-color: #f2f8ff;
  padding: 0px 0px 0px 5.65%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @include media-lg{
    flex-direction: column;
    padding: 40px 5.65%;
    align-items: flex-start;
  }
}
.outlookHeading {
  color: var(--Neutral-Black, #00142d);
  font-size: 34px;
  font-weight: 800;
  margin-bottom: 32px;
  line-height: 150%;
  width:70%;
  @include media-lg{
    font-size: 30px;
    width:100%;
  }
}
.appImageContainer {
  height: 100vh;
  width: 90vh;
  img {
    height: 100%;
    width: 100%;
  }
  @include media-lg{
    height:auto;
    width:auto;
    margin-top: 36px;

  }
}
.facilities {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
}
.facilityTitle {
  color: #303c4a;
  font-size: 18px;
  line-height: 27px;
}
.playstoreContainer{
  display: flex;
  justify-content: flex-start;
@include media-lg{
  justify-content: center;
}
}
.playBtn{
  width: 192px;
height: 56px;
}