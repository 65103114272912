@import "../../assets/styles/mixins";
.grid-container {
  display: grid;
  gap: 48px;
  grid-column-gap: 72px;
  padding: 64px 8.25% 0 8.25%;
  grid-template-columns: 1fr 1fr 1fr;

  @include media-lg {
    grid-template-columns: 1fr 1fr;
  }

  @include media-md {
    grid-template-columns: 1fr;
  }
}

.grid-item {
  text-align: center;
  text-align: left;
}
.dataHeading {
  color: var(--Neutral-Gray-1, #303c4a);
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
}
.dataValue {
  color: var(--Neutral-Gray-1, #303c4a);
  font-size: 18px;
  font-weight: 400;
  line-height: 150%; /* 27px */
  margin-top: 8px;
  max-width: 352px;
}
.addressField {
  grid-column: 1 / span 3;
  grid-row: 4;
  @include media-md {
    grid-column: auto;
    grid-row: auto;
  }
}
.addressWidth {
  max-width: 750px;
  @include media-md {
    max-width: 320px;
  }
}
