@import "../../assets/styles/mixins";

.heroSectionFAQ_container{
    padding:80px 5.65%;
    background: #F2F8FF;
    @include media-lg{
        padding:42px 5.65%
    }
}
.faqtitle{
    font-size: 34px;
    font-weight: 800;

}
.subTitle{
   font-size: 18px;
   color:#303C4A; 
}
.faqContainer{
    border: 1px solid #D4D4D4;
    padding:16px 24px;
    border-radius: 8px;
    margin-bottom: 24px;
    justify-content: space-between;
}
.question{
    font-size: 18px;
    font-weight: 600;
    color:#303C4A;
    margin: 0px;
    margin-right: 16px;
}
.answer{
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
    color:#626870;
    margin: 0px;
    padding-top: 16px;
}

@include media-md{
    .faqtitle{
        font-size: 30px;
        font-weight: 800;
        line-height: 120%;
    
    }
    .subTitle{
       font-size: 16px;
       color:#303C4A; 
    } 
    .question{
        font-size: 16px;
        font-weight: 600;
        color:#303C4A;
        margin: 0px;
        margin-right: 16px;
    }
    .answer{
        font-size: 14px;
        line-height: 27px;
        font-weight: 400;
        color:#626870;
        margin: 0px;
        padding-top: 16px;
    } 
}