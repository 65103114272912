@import "../../assets/styles/mixins";

.pannetwork {
  padding: 64px 11.25%;
  width: 100%;
  @include flex(row-reverse, space-between, center);
  &__left {
    display: flex;
    flex-direction: column;
    .heading {
      font-size: 44px;
      font-weight: 800;
      line-height: 52px;
    }
    .para {
      letter-spacing: 0px;
      font-size: 16px;
      font-weight: 500;
      line-height: 22.4px;
      width: 536px;
    }
  }
  &__right-image {
    img {
      height: auto;
    }
  }
}
@include media-lg {
  .pannetwork {
    @include flex(column, center, center);
    gap: 40px;
    &__left {
      align-items: center;
      .heading {
        text-align: center;
      }
      .para {
        width: 100%;
        text-align: left;
      }
    }
  }
}
@include media-md {
  .pannetwork {
    padding: 56px 6.25% 45px 6.25%;
    @include flex(column, center, center);
    gap: 40px;
    &__left {
      align-items: center;
      .heading {
        text-align: center;
        margin-top: 0px;
        margin-bottom: 16px;
      }
      .para {
        margin: 0px;

        width: 100%;
      }
    }
  }
}
@include media-sm {
  .pannetwork {
    padding: 56px 6.25% 45px 6.25%;
    @include flex(column, center, center);
    gap: 40px;
    &__left {
      .heading {
        text-align: center;
        font-size: 30px;
      }
      .para {
        font-size: 14px;
        width: 100%;
      }
    }
  }
}
