@import "../../assets/styles/mixins";

.container {
  width: 100%;
  padding: 94px 5.65%;

  .heading {
    h1 {
      font-family: Mulish;
      font-size: 44px;
      font-weight: 800;
      text-align: left;
    }
  }

  .component {
    margin: 4rem auto;

    width: 100%;
    background: rgba(225, 225, 234, 0.2);
    border-radius: 24px;
    padding: 40px;
    @include flex(row, space-around, center);
  }
}

.font-weight-bold {
  font-weight: 700;
}

.faq {
  width: 100%;
  padding: 10px 3.25%;

  &__question {
  
    width: 100%;
    border-bottom: 1px solid #f2f2f2;
  }

  &__question-button {
    width: 100%;
    @include flex(row, space-between, center);
    border: none;
    background-color: transparent;
    padding: 4px;
    cursor: pointer;
    color: black;
    font-family: Mulish;
    font-size: 14px;
    font-weight: 700;
    padding-top: 18px;
    padding-bottom: 18px;
    text-align: left;

    &::after {
      content: url(../../assets/images/faq/arrow.svg);
      margin-right: -14px;
    }
  }

  &__desc {
    padding-top: 11px;
    padding-right: 17px;
    margin-top: 0;
    margin-bottom: 0;
    height: 0;
    overflow: hidden;
 
  }
}

.faq__desc.show-description {
  height: auto;
  margin-bottom: 15px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-left: -37px;
}

.faq__question-button::after {
  transition: transform 600ms ease-out;
}

.font-weight-bold::after {
  transform: rotate(-180deg);
}

@include media-lg {
  .container {
    margin-bottom: -50px;

    .heading {
      margin-left: 0px;

      h1 {
        text-align: center;
      }
    }

    .faq {
      padding-left: 4.25%;
    }

    .component {
      flex-direction: column;
    }
  }
}

@include media-md {
  .container {
    padding: 94px 6.25%;
    margin-bottom: -50px;
    .faq {
      padding: 0px;
    }
    &__question-button {
      width: 90%;
    }
    .heading {
      margin-left: 0px;

      h1 {
        text-align: center;
      }
    }

    .component {
      @include flex(column, center, center);
      padding: 24px;
    }
  }
}

@include media-sm {
  .container {
    padding: 94px 6.25%;
    margin-bottom: -50px;

    .heading {
      margin-left: 0px;

      h1 {
        text-align: center;
      }
    }

    .faq {
      padding: 0px 0px;
      &__question {
        height: auto;
      }
    }
    &__question-button {
      text-align: left;
      width: 70%;
    }
    .component {
      @include flex(column, center, center);
      padding: 24px;
    }
  }
}
