@import "../../assets/styles/mixins";
@import "../../assets/styles/breakpoints";

@mixin full-horizontal-scroll {
  &:last-child {
    position: relative;
    &::after {
      position: absolute;
      content: "";
      top: 0;
      right: -24px;
      width: 24px;
      height: 100%;
    }
  }
}
.hero__usps {
  display: none;
}
.adwordsContainer {
  @media (max-width: #{$screen-lg-max}) {
    &__form {
      width: 352px;
    }

    .customersSection {
      width: 100%;
      .customers {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        place-items: center;
        grid-row-gap: 1rem;
        width: 80%;
        margin: 0 auto;
      }
      .desktop {
        display: none;
      }
    }

    .servicesSection {
      .services {
        @include flex(column, center, flex-start);
        @include media-md {
          @include flex(column, center, center);
        }
        &__item {
          max-width: 376px;
        }
      }
    }

    .cardsSectionWrapper {
      &__heading {
        width: 100%;
        text-align: left;
      }
      &__benefits {
        .benefitCard {
          min-width: auto;
          max-width: 296px;
        }
      }
    }

    .testimonialsSection {
      &__list {
        place-items: center;
        grid-template-columns: 1fr;
      }

      &__card {
        max-width: 512px;
        justify-self: center;
        &:last-child {
          grid-column-end: 2;
        }
      }
    }
    &__content {
      .fabricsRangeSection {
        &__part {
          width: calc(100vw - 400px);
        }
      }
    }
  }
  @include media-lg {
    .customersSection {
      .customers {
        width: 50%;
        margin: 0px;
      }
    }
  }
  @include media-md {
    .hero__usps {
      display: grid;
      place-items: flex-start;
      align-items: center;
      grid-row-gap: 24px;
      margin-top: 2rem;
      border-radius: 16px;
      padding-left: 6.25%;
      &-item {
        display: flex;
        align-items: center;
        gap: 11px;
        span {
          font-size: 16px;
          font-weight: 400;
          color: #303c4a;
        }
      }
    }
    .hero-adwords .hero__content {
      width: 100%;

      .heroadwords__summary p {
        padding-bottom: 0;
        font-size: 16px;
        line-height: 21px;
        max-width: 290px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        margin-bottom: 6px;
      }
    }
    &__content {
      width: 100%;

      .fabricsRangeSection .fabricsRange {
        flex-wrap: wrap;
      }

      .section__heading,
      .cardsSectionWrapper__heading,
      .certificates__heading {
        font-size: 30px;
        line-height: 38px;
        max-width: 264px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 320px;
      }

      .section__subheading,
      .cardsSectionWrapper__description {
        font-size: 16px;
        line-height: 20px;
        max-width: 312px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }
    }

    &__form {
      position: initial;
      display: none;
    }

    &__customernote {
      display: block;
    }

    .bottomSheet {
      display: block;
      position: fixed;
      border-radius: 1px;
      height: 68px;
      .btn {
        border-radius: 8px;
        height: 40px;
        margin-top: 15px;
      }
      .logoLink {
        display: none;
      }
    }

    .hero-wrapper {
      height: 70vh;
    }
    .hero__content {
      padding: 16px 24px;
      justify-content: flex-start;

      &_main {
        margin-top: 48px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
      }
      .logo {
        padding-top: 0;
        width: 110px;
        display: block;
      }

      .hero__heading {
        font-size: 24px;
        font-weight: 900;
        max-width: 312px;
        @include center-container();
      }

      .hero__usps {
        display: none;
        grid-template-columns: 1fr;
        margin-top: 2em;
        max-width: 284px;
        @include center-container();

        &-item {
          span {
            font-size: 14px;
          }
        }
      }
    }

    .customersSection {
      padding-top: 0em;
      padding-bottom: 2em;
      padding: 82px 6px 60px 6px;
      .customers {
        width: 100%;
        grid-column-gap: 0px;
        img {
          width: 84%;
          border-radius: 16px;
        }
      }

      &__meta {
        font-size: 18px;
        font-weight: 400;
        width: auto;
      }
    }

    .fabricsRangeSection {
      &__part {
        width: 100%;
        .mainContent {
          padding: 56px 20px 0px 20px;
        }
        .fabricsRange {
          padding-left: 20px;
          flex-direction: row;
          flex-wrap: nowrap;
          overflow-x: auto;
          justify-content: space-between;
          gap: 0px;
          &__item {
            margin-right: 24px !important;
            @include full-horizontal-scroll();
          }
        }
      }
    }

    .servicesSection {
      padding-top: 5em;
      padding: 56px 20px 48px 20px;
      @media (min-width: 300px) and (max-width: 400px) {
        .services {
          &__item {
            width: 300px;
            img {
              width: 300px;
            }
          }
        }
      }
    }

    .cardsSectionWrapper {
      width: 100%;
      &__benefits {
        .benefitCard {
          width: 100%;
          max-width: initial;

          &__contentWrapper_title {
            font-size: 20px;
          }
        }
      }
    }
    .testimonialsSection {
      width: auto;

      &__card .content {
        .description {
          font-size: 14px;
        }

        .metadata {
          .heading {
            font-size: 16px;
          }

          .company {
            font-size: 14px;
          }
        }
      }
    }
    .aboutSection {
      .content {
        margin-top: 32px;
      }
      img {
        transform: scale(1.1);
      }
    }
  }

  @media (max-width: 426px) and (max-height: 676px) {
    .hero-adwords {
      height: 85vh;
    }
  }

  @media (max-width: 426px) and (max-height: 598px) {
    .hero-adwords {
      height: 90vh;

      .hero__content {
        &_main {
          margin-top: 32px;
        }
        .hero__usps {
          margin-top: 0;
        }
      }
    }
  }
}
