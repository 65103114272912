@import "../../assets/styles/mixins";

.community {
  padding: 80px 11.25% 126px;
  &__first {
    @include flex(column, center, center);
    .header {
      display: flex;
      justify-content: center;
      padding-bottom: 24px;
      .image {
        padding-right: 24px;
      }
      .heading {
        font-size: 40px;
        font-weight: 800;
        line-height: 48px;
        color: var(--fill-neutral-black);
      }
    }
    .header-info {
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      max-width: 728px;
      text-align: center;
      color: var(--fill-neutal-gray);
    }
  }
  &__second {
    display: flex;
    flex-direction: column;
    gap: 144px;
    margin-top: 18%;
    .row {
      display: flex;
      justify-content: space-between;
      .left {
        margin-top: -40px;
        .text {
          font-size: 28px;
          font-weight: 700;
          line-height: 36px;
          padding-bottom: 16px;
          color: var(--fill-neutral-black);
        }
        .subtext {
          font-size: 18px;
          font-weight: 400;
          line-height: 27px;
          max-width: 402px;
          color: var(--fill-neutal-gray);
        }
      }
      .right {
        background-color: var(--fill-green-secondary);
        border-radius: 16px;
        padding-top: 80px;
        width: 520px;
        height: 232px;
        .slider {
          height: 232px;
          top: -120%;
          left: 4.5%;
          .slick-dots {
            left: -30px;
            bottom: -50px;
            li {
              margin: 0 24px;
              opacity: 0.5;
              .triangle {
                visibility: hidden;
              }
            }
            .slick-active {
              opacity: 1;
            }
          }
          img {
            width: 472px !important;
            height: 256px;
          }
          .custom-dots-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            .triangle {
              width: 20px !important;
              height: 20px;
              margin-bottom: 12px;
            }
            .dots {
              width: 28px !important;
              height: 28px;
              border-radius: 16px;
            }
          }
          .slick-active {
            .dots {
              transform: scale(1.5);
              object-fit: cover;
            }
            .triangle {
              visibility: visible !important;
            }
          }
        }
      }
    }
    .row2 {
      flex-direction: row-reverse;
      align-items: flex-end;
    }
  }
}
@include media-xl {
  .community {
    &__second {
      .row {
        flex-direction: column;
        align-items: center;
        .left {
          .text {
            text-align: center;
          }
          .subtext {
            text-align: center;
          }
        }
        .right {
          margin-top: 144px;
        }
      }
    }
  }
}
@include media-sm {
  .community {
    padding: 64px 24px;
    &__first {
      align-items: unset;
      padding-bottom: 32px;
      .header {
        flex-direction: column;
        padding-bottom: 16px;
        .image {
          @include communityImage;
        }
        .heading {
          font-size: 30px;
          line-height: 130%;
          text-align: center;
        }
      }
      .header-info {
        font-size: 16px;
        text-align: left;
      }
    }
    &__second {
      gap: 84px;
      .row {
        flex-direction: column-reverse;
        .left {
          .text {
            font-size: 24px;
            font-weight: 800;
            text-align: left;
            padding-bottom: 8px;
          }
          .subtext {
            font-size: 16px;
            text-align: left;
          }
        }
        .right {
          width: 312px;
          height: 200px;
          margin: 64px 0px;
          .slider {
            top: -146%;
            height: 200px;
            .slick-dots {
              left: -16px;
              bottom: -38px;
            }
            img {
              width: 280px !important;
              height: 207px;
              object-fit: cover;
              border-radius: 16px;
            }
          }
        }
      }
    }
  }
}
