@import "../../assets/styles/mixins";
.part {
  padding: 80px 5.65% 96px 5.65%;
  .temp {
    display: flex;
    margin-bottom: 56px;
    justify-content: space-between;
  }
  .heading {
    @include font(44px, 56px, 800, var(--fill-black));
    font-family: var(--font-heading);
    margin-bottom: 0px;
  }
  .partner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 24px;
    .pimage {
      img {
        height: 200px;
      }
    }
  }
  .bds {
    display: flex;
    justify-content: center;
  }
  #b2 {
    display: none;
  }
  #b1 {
    padding: 12px 16px;
    margin-top: 8px;
  }
}
@include media-lg {
  .part {
    .heading {
      margin: 0px;
    }
    #b1 {
      margin-top: 0px;
    }
  }
}
@include media-sm;
@include media-md {
  .part {
    padding: 50px 6.25% 42px 6.25%;
    place-items: center;
    .temp {
      justify-content: center;
    }
    .heading {
      @include font(30px, 48px, 800, var(--fill-black));
      text-align: center;
      margin: 0px;
    }
    .partner {
      display: grid;
      place-items: center;
      grid-template-columns: 1fr;
      grid-row-gap: 16px;
      margin-top: -20px;
    }
    #b1 {
      display: none;
    }
    #b2 {
      display: flex;
      align-items: center;
      margin-top: 32px;
    }
  }
}
.imgDetailsTxt {
  color: var(--Neutral-Gray-1, #303c4a);
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 140%;
}
.imgDetailsContainer {
  background-color: #f2f8ff;
  border: 1px solid #cee4ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 16px;
  height: 200px;
  width: 100%;
  align-items: center;
}
.imgDetailsBox {
  width: 80px;
  height: 80px;
  margin-bottom: 28px;
}
.widthLess {
  width: 50%;
}
.widthMore {
  width: 80%;
}

.paddingSupplier{
  padding: 80px 11.25% 96px 11.25%;
}