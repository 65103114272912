.supplierUserNameContainer {
  margin-top: 64px;
  padding: 0 6.25%;
  margin-bottom: 90px;
}
.teamMember {
  color: var(--Neutral-Black, #00142d);
  font-family: Mulish;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.supplierTeamMember {
  border-radius: 16px;
  background: var(--Primary-Violet-3, #f9faff);
  display: flex;
  padding: 24px;
  align-items: center;
  gap: 24px;
  margin-top: 32px;
}
.supplierUserName {
  color: var(--Neutral-Gray-1, #303c4a);
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
}
