@import "../../assets/styles/mixins";

.banner {
  padding: 22px 11.25%;
  width: 100%;
  @include flex(row, space-between, center);
  background-image: url("../../assets/images/banner/design.svg"),
    linear-gradient(269.01deg, #cee4ff 51.32%, rgba(206, 228, 255, 0) 100%);
  background-repeat: no-repeat;
  background-position: right;
  &__left {
    @include flex(row, center, center);
    gap: 35px;
    img {
      width: 120px;
      height: 120px;
    }
    .para {
      font-family: Mulish;
      @include font(24px, 34px, 700, #333340);
    }
  }
  &__right {
    @include flex(row, center, center);
    .btn {
      width: 180px;
      z-index: 99;
      padding: 0.8rem 1rem !important;
      border-radius: 8px;
    }
  }
}
@include media-md {
  .banner {
    background-image: url("../../assets/images/banner/design.svg"),
      linear-gradient(34.07deg, #cee4ff 4.09%, rgba(206, 228, 255, 0) 98.57%);
    background-position: -14% 123%;
    @include flex(column, center, center);
    gap: 24px;
    padding-top: 56px;
    padding-bottom: 56px;
    &__left {
      @include flex(column, center, center);
      gap: 24px;
      padding-left: 0px;
      .para {
        font-size: 24px;
        text-align: center;
        margin: 0px;
      }
    }
    &__right {
      @include flex(column, center, center);
      .btn {
        height: 40px;
        width: 219px;
        font-size: 16px;
      }
    }
  }
}
@include media-sm {
  .banner {
    background-position: -25% 140%;
    @include flex(column, center, center);
    gap: 24px;
    padding-top: 56px;
    padding-bottom: 56px;
    &__left {
      @include flex(column, center, center);
      gap: 24px;
      padding-left: 0px;
      .para {
        font-size: 24px;
        text-align: center;
      }
    }
    &__right {
      @include flex(column, center, center);
    }
  }
}
