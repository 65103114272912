@import "./breakpoints";

// Small devices
@mixin media-sm {
  @media (max-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin media-md {
  @media (max-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin media-lg {
  @media (max-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin media-xl {
  @media (max-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin flex(
  $flex-direction: row,
  $justify-content: flex-start,
  $align-items: center
) {
  display: flex;
  flex-direction: $flex-direction;
  justify-content: $justify-content;
  align-items: $align-items;
}
@mixin communityImage{
  height: 48px;
  width: 48px;
  padding-right: 0px; 
  margin-left: auto;
  margin-right: auto;
}

@mixin font($font-size, $line-height, $font-weight, $color) {
  font-size: $font-size;
  line-height: $line-height;
  font-weight: $font-weight;
  color: $color;
}

// Spacing between description and actual content fo section
@mixin description-spacing {
  margin-top: 56px;
}

// Usage: Wrap the section content in .content class
@mixin content-spacing {
  .content {
    @include description-spacing();
  }
}

// Center container when max-width is defined
@mixin center-container {
  margin-left: auto;
  margin-right: auto;
}
