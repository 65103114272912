@import "../../assets/styles/mixins";
.productContainer-wrapper {
  background-color: #f2f8ff;
  padding: 80px 5.65%;
  
}
.orderslider {

  .slick-list {
    padding-top: 0px;
    padding-bottom: 60px;
    padding-left: 0px;
  }
  .slick-slide div {
    width: 208px;
    @include media-xl {
      width: 180px;
    }

    @include media-lg {
      width: 180px;
    }
    @include media-md {
      width: 160px;
    }
    img {
      width: 208px;
      @include media-xl {
        width: 180px;
      }
      @include media-lg {
        width: 200px;
      }
      @include media-md {
        width: 148px;
      }
    }
    transition: transform 0.2s;
  }
  .slick-arrow {
    z-index: 10;
    height: 40px;
    width: 40px;
  }
  .slick-prev {
    left: 1.5%;
  }
  .slick-next {
    right: 1.5%;
  }
  .image {
    height: 160px;
    cursor: pointer;
    border-radius: 16px;
  }
  .slick-dots {
    bottom: -12px !important;
    li {
      width: 16px;
    }
    li button::before {
      font-size: 10px !important;
      color: var(--fill-light-gray-2);
    }
    .slick-active button::before {
      opacity: 1;
      color: #0067e2;
      font-size: 10px;
      outline: 2px solid #0067e2 !important;
      outline-offset: 0px;
      line-height: 21px;
      margin-right: 4px;
      border-radius: 50%;
    }
  }
}
.title {
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 8px;
  margin-top: 0px;
  @include media-sm;
  @include media-md;
  @include media-lg {
    font-size: 30px;
    line-height: 36px;
  }
}
.subTitle {
  font-size: 18px;
  margin-bottom: 48px;
  color: #303c4a;
  margin-top: 24px;
  @include media-sm;
  @include media-md;
  @include media-lg {
    font-size: 16px;
    line-height: 24px;
  }
}
.card_wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-column-gap: 24px;
}
.card_specifications {
  display: flex;
  margin-top: 8px;
  flex-wrap: wrap;
}
.threadcountcontainer {
  background-color: white;
  padding: 4px;
  border-radius: 8px;
  margin-right: 6px;
  display: flex;
  justify-content: center;
  width: auto !important;
  height: 25px;
}
p {
  margin: 0px;
}
.threadcount {
  color: #3944b4;
  font-size: 16px;
  font-weight: 700;
  @include media-md{
    font-size: 12px;
  }
}
.specifications {
  font-size: 16px;
  line-height: 24px;
  color: #303c4a;
  font-weight: 600;
  margin-right: 6px;
  margin-bottom: 6px;
  @include media-md{
    font-size: 12px;
  }
}
.bar {
  color: #d4d4d4;
  font-size: 20px;
  margin-right: 6px;
  font-weight: 400;
}
.order_card {
  width: 266px;
  margin-bottom: 24px;

  .specs-container {
    min-height: 120px;
  }
}
.orderNow {
  color: #0067e2;
  font-size: 16px;
  font-weight: 700;
  @include media-md{
    font-size: 12px;
  }
}
.slick-slide img {
  .orderCardImg {
    height: 104px;
  }
}

.arrowOrder{
  width: 20px !important;
  height:20px;
}


.test{
  width: -webkit-fill-available;
  border-radius: 16px;
  // width: 100px !important
}