@import "../../../assets/styles/mixins";
.aboutus {
  padding: 96px 11.25% 56px 11.25%;

  .tima {
    height: auto;
    width: 100%;
    border-radius: 20px;
  }
  .heading {
    @include font(44px, 52px, 800, var(--fill-black));
    font-family: var(--font-heading);

    padding-top: 56px;
    color: #000126;
  }

  .writ {
    display: flex;
    padding-bottom: 64px;
    justify-content: space-between;
    @include font(16px, 22.4px, 500, var(--fill-black));
    font-family: var(--font-heading);
    .description {
      width: 448px;
    }
  }
}

@include media-lg {
  .aboutus {
    padding: 50px 6.25% 96px 6.25%;
    .tima {
      max-width: 870px;
      padding-top: 10%;
    }
  }
}
@include media-md {
  .aboutus {
    padding: 24px 6.25% 36px 6.25%;

    padding: 0%;
    .heading {
      text-align: center;
      justify-content: center;
      padding-top: 0px;
      margin-top: 16px;
      margin-bottom: 16px;
      font-size: 32px;
    }
    .tima {
      width: 100%;
      border-radius: 0px;
    }
    .writ {
      flex-direction: column;
      font-size: 14px;
      align-items: center;
    }
    p {
      margin-top: 0px;
    }
  }
}
@include media-sm {
  .aboutus {
    padding-bottom: 56px;
    .tima {
      height: auto;
      width: 100%;
      padding-top: 11%;
    }
    .heading {
      padding-top: 0px;
      margin-bottom: 16px;
      margin-top: 16px;
      text-align: center;
      font-size: 32px;
    }
    .writ {
      flex-direction: column;
      gap: 0px;
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 1px;
      @include font(14px, 148%, 400, var(--fill-black));
      font-family: var(--font-heading);
      .description {
        width: 100%;
        p {
          margin-top: 0px;
        }
      }
    }
  }
}
