@import "../../assets/styles/mixins";
.buttons {
  @include flex(row, flex-start, center);
  margin-bottom: 2.5%;
}
.button {
  margin-right: 8px;
  height: 37px;
  width: 78px;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  color: #676773;
  padding: 8px 10px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}
.buttonClick {
  border: 1px solid #0067e2;
  color: #0067e2;
}
.contact-section {
  padding: 80px 11.25%;
  &__content {
    display: flex;
  }
  &__content2 {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  &__left {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   width:70%
  }
  &__heading {

    font-style: normal;
    font-weight: bold;
    line-height: 52px;
    margin-bottom: 8px;
    font-size: 30px;
  }

  &__subheading {
    font-style: normal;
    width: 80%;
    color: var(--fill-dark-gray-2);
    line-height: 140%;
  }
}


.form {
  padding: 32px 40px 32px 40px;
  border-radius: 24px;
  background-color: var(--fill-blue-light);
  border: 1px solid #ddf0ff;
  width:-webkit-fill-available;

  .formContent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;

    .field {
      // padding-bottom: 16px;
    }

    label {
      font-family: "Mulish", sans-serif;
      margin-bottom: 8px;
      font-style: regular;
      font-size: 14px;
      color: var(--fill-dark-gray-2);
      display: inline-block;
      line-height: 20px;
      width: 100%;
    }
    input,
    textarea {
      padding: 10px 16px;
      width: 100%;
      border-radius: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      border: 1px solid var(--fill-light-gray-2);
      &:active,
      &:focus,
      &:hover {
        outline: none;
      }

      &:focus {
        border: 1px solid var(--fill-blue-1);
        font-weight: 800;
      }

      &::placeholder {
        color: var(--fill-dark-gray);
        font-size: 14px;
        font-family: "Manrope", sans-serif;
      }
    }

    textarea {
      resize: none;
      height: calc(100% - 23px);
    }

    .btn[type="submit"] {
      margin-top: 12px;
      margin-left: 350px;
      padding: 10px 16px;
      font-size: 14px;
      line-height: 20px;
      color: var(--fill-white);
      border: none;
      outline: none;
      cursor: pointer;
      &:disabled {
        background: rgba(0, 103, 226, 0.5);
      }
    }

    .h-full {
      height: 103%;
    }

    .checkbox-group {
      display: flex;
      transition: all 0.5s;
      position: relative;

      label {
        display: flex;
        width: auto;
        margin-bottom: 8px;
        cursor: pointer;
      }

      span {
        border: 1px solid var(--fill-light-gray-2);
      }

      input[type="radio"],
      input[type="checkbox"] {
        display: none;
      }

      input[type="radio"] + span,
      input[type="checkbox"] + span {
        background-color: var(--fill-white);
        border-radius: 24px;
        color: var(--fill-dark-gray-3);
        display: block;
        font-size: 14px;
        font-weight: 600;
        height: auto;
        margin-right: 16px;
        padding: 10px 22px;
      }

      input[type="radio"]:checked + span,
      input[type="checkbox"]:checked + span {
        border: 1px solid var(--fill-primary);
        color: var(--fill-primary);
        position: relative;
        background-color: var(--fill-blue-secondary);
      }

      input[type="radio"]:disabled + span {
        opacity: 0.5;
      }
    }

    input:disabled,
    input:disabled + span,
    textarea:disabled {
      opacity: 0.7;
      background-color: var(--fill-gray) !important;
    }
  }
  .flex-center {
    @include flex(row, right, center);
  }
  #submitBtn {
    margin-top: 5px;
    border-radius: 8px !important;
    padding: 12px 30px;
    font-size: 16px;
    height: 48px;
    width: calc(50% - 8px);
  }

  .PhoneInput {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    border-radius: 8px;
    border: 1px solid transparent;
    &--focus {
      border: 1px solid var(--fill-blue-1);
    }

    input:focus {
      border: 0;
    }
  }
  .PhoneInputCountry {
    background: var(--fill-gray);
    background: var(--fill-gray);
    margin: 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top: 1px solid var(--fill-light-gray-2);
    border-bottom: 1px solid var(--fill-light-gray-2);
    border-left: 1px solid var(--fill-light-gray-2);
    padding: 10px 12px 10px 12px;
  }
  .PhoneInputCountryIcon {
    overflow: hidden;
    margin-right: 6px;

    &--border {
      box-shadow: none;
    }
  }

  .PhoneInputCountrySelectArrow {
    border-width: 5px;
    transform: translateY(4px);
    border-color: var(--fill-dark-gray-3);
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    opacity: 1;
    border-style: solid;
  }

  .field-contact {
    position: relative;
    .country-call-code {
      position: absolute;
      left: 70px;
      top: 38px;
      width: 34px;
      font-size: 14px;
      text-align: right;
    }

    input[type="tel"] {
      padding-left: 16px;
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .submittedBanner {
    display: flex;
    align-items: center;
    margin-top: 24px;

    svg {
      width: 48px;
      height: 48px;
    }

    span {
      padding-left: 10px;
      font-size: 16px;
      font-weight: 700;
    }
  }
}
@include media-xl {
  .contact-section {
    &__content {
      display: flex;
      flex-direction: column
    }
    &__content2 {
      display: flex;
      flex-direction: column
    }
    &__left {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    &__subheading {
      width: 100%;
    }
  }
  .form {
    margin-top: 35px;
  }
}
@include media-lg {
  .buttons {
    margin-bottom: 4%;
  }
  .contact-section {
    padding: 80px 6.25%;
    &__content {
    display: flex;
      flex-direction: column
    }
    &__content2 {
    display: flex;
      flex-direction: column
    }
    &__left {
    display: flex;
      flex-direction: column
    }
  }
  .form {
    .formContent {
      grid-template-columns: 1fr;
      grid-gap: 0;
      textarea {
        height: 100px;
      }
    }
    #submitBtn {
      width: 100%;
    }
    .flex-center {
      @include flex(row, flex-start, flex-start);
    }
  }
}
@media (max-width: 768px) {
  .contact-section {
    &__content {
      display: flex;
      flex-direction: column
    }
  }
  .section-description {
    // text-align: center;
  }
  .form {
    box-shadow: none;
    background-color: var(--fill-blue-light);
    border: 0;
    padding: 32px 6.25% 32px 6.25%;
    margin-top: 29px;
    .formContent {
      grid-template-columns: 1fr;
      grid-gap: 0;

      label {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 6px;
      }
      input {
        padding: 10px 12px;
        border-radius: 8px;
        font-size: 14px;
        line-height: 14px;
      }
      input[type="submit"] {
        margin-top: 0;
        padding: 12px 18px;
        font-size: 14px;
        line-height: 14px;
      }

      textarea {
        height: 100px;
      }

      @media screen and (-webkit-min-device-pixel-ratio: 0) {
        select,
        textarea,
        input {
          font-size: 16px;
        }
      }
    }
    .field-contact {
      .country-call-code {
        top: 36px;
        left: 66px;
      }

      input[type="tel"] {
        padding-left: 16px;
      }
    }
    .submittedBanner {
      svg {
        width: 32px;
        height: 32px;
      }
    }

    #submitBtn {
      width: 100%;
    }
  }
}
.formWidth{
  width:700px;
  @include media-xl{
    width:-webkit-fill-available;
  }
 
}
.leftSectionWidth{
  width:30%;
  @include media-xl{
    width: 100%;
  }
  
}
.homePagePadding{
padding:80px 5.65%
}