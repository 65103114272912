@import "../../assets/styles/mixins";

.category {
  padding: 144px 7.25% 144px 7.25%;
  .row {
    display: flex;
    .column {
      position: relative;
      margin: auto;
      overflow: hidden;
      border-radius: 16px;
      .image {
        max-width: 100%;
      }
      .text {
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        color: white;
        position: absolute;
        left: 2rem;
        bottom: 1rem;
      }
      .arrow {
        position: absolute;
        right: 1.5rem;
        bottom: 1rem;
        visibility: hidden;
        img {
          width: 32px;
          height: 32px;
        }
      }
    }
    .column:hover{
      .image{
        transform: scale(1.25);
      }
      .arrow{
        visibility: visible;
      }
    }
  }
}
@include media-md {
  .category {
    padding: 64px 0px 64px 6.25%;
    background-color: var(--fill-blue);
    .image {
      height: 88px;
      width: 88px;
      object-fit: cover;
      border-radius: 16px;
    }
    .row {
      .column {
        padding-right: 24px;
        .text {
          font-weight: 700;
          font-size: 18px;
          line-height: 25px;
          left: 16%;
          bottom: 13%;
        }
      }
      .column:hover{
        .image{
          transform: none;
        }
        .arrow{
          visibility: hidden;
        }
      }
    }
  }
}
@media (min-width: 300px) and (max-width: 400px){
  .category{
    .row{
      .column{
        .Linen{
          left: 20%;
        }
      }
    }
  }
}