@import "../../assets/styles/mixins";

.customerBaseContainer {
  padding: 80px 5.65%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @include media-lg{
    flex-direction: column;
  }
}
.outlookHeading {
  color: var(--Neutral-Black, #00142d);
  font-size: 34px;
  font-weight: 800;
  margin-bottom: 32px;
  line-height: 120%;
  @include media-lg{
    font-size: 30px;
    line-height: 150%;
  }
}

.facilities {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
}
.facilityTitle {
  color: #303c4a;
  font-size: 18px;
  line-height: 27px;
}
.customerBaseoutlookHeading{
  color: var(--Neutral-Black, #00142d);
  font-size: 34px;
  font-weight: 800;
  margin-bottom: 32px;
  line-height: 120%;
  width:70%;
  @include media-lg{
    width:100%;
    line-height: 150%;
  }
}
.customerBaseSubHeading {
  color: var(--Neutral-Gray-1, #303c4a);
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  width: 62%;
  @include media-lg{
    width: 100%;
  }
}
.customerMapImageContainer {
  height: 74vh;
  margin-right: 56px;
  @include media-lg{
    height:auto;
    margin-top: 42px;
    margin-right: 0px;
  }
  img {
    height: 100%;
    width: 100%;
  }
}
.customerBaseSection1 {
  width: 50%;
  @include media-lg{
    width:100%
  }
}
