@import "../../assets/styles/mixins";

.benefitCard {
  @include flex(column, center, center);
  min-width: 376px;
  flex: 1;
  background-color: var(--fill-footer);
  padding: 24px;
  border: 1px solid var(--fill-primary2);
  &__contentWrapper {
    flex-grow: 1;
    margin-left: 16px;
    max-width: 376px;

    &_title {
      @include font(20px, 25px, 700, var(--fill-blue-1));
    }
    &_description {
      @include font(18px, 22px, 400, var(--fill-dark-gray-2));
      margin-top: 8px;
    }
  }
}
#care {
  border-radius: 16px 0px 0px 0px;
}
#visibility {
  border-radius: 0px 16px 0px 0px;
}
#check {
  border-radius: 0px 0px 0px 16px;
}
#delivery {
  border-radius: 0px 0px 16px 0px;
}
.cardsSectionWrapper {
  width: 100vw;
  padding: 56px 11.25% 80px 11.25%;

  &__heading {
    @include font(42px, 56px, 800, var(--fill-black));
    @include flex(row, center, center);
    width: 100%;
    padding-bottom: 24px;
    font-family: var(--font-heading);
  }

  &__description {
    @include font(16px, 24px, 400, var(--fill-gray-1));
    text-align: center;
    margin-bottom: 80px;
    max-width: 724px;
  }

  &__subheading {
    @include font(18px, 24px, bold, var(--fill-black));
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.08rem;
    padding-bottom: 8px;
  }

  &__benefits {
    display: grid;
    grid-row-gap: 24px;
    place-items: flex-start;
    grid-template-columns: 0.2fr 0.2fr;
    grid-column-gap: 24px;
    max-width: 960px;
  }
}
@media (max-width: 1300px) {
  .cardsSectionWrapper {
    #care {
      border-radius: 16px;
    }
    #visibility {
      border-radius: 16px;
    }
    #check {
      border-radius: 16px;
    }
    #delivery {
      border-radius: 16px;
    }
    &__heading{
      justify-content: flex-start;
    }
    &__description{
      text-align: left;
    }
    &__benefits {
      grid-template-columns: 1fr;
    }
  }
}
@include media-lg {
  .cardsSectionWrapper {
    &__heading {
      @include font(30px, 36px, 800, var(--fill-black));
    }
    &__description {
      @include font(14px, 22px, 400, var(--fill-gray-1));
      margin-bottom: 56px;
    }

    &__benefits {
      grid-template-columns: 1fr;
    }
  }

  .benefitCard {
    max-width: 100%;
    min-width: 100%;
    @include flex(column center, center);

    &__contentWrapper {
      &_title {
        @include font(20px, 28px, 700, var(--fill-blue-1));
      }
      &_description {
        @include font(16px, 19.12px, 400, var(--fill-dark-gray-2));
      }
    }
  }
}

@include media-sm;
@include media-md {
  .cardsSectionWrapper {
    padding: 56px 20px 48px 20px !important;
    &__benefits {
      grid-template-columns: 1fr;
      grid-row-gap: 24px;
    }

    .benefitCard {
      padding: 24px 9px;
      align-items: center;
      &__iconWrapper svg {
        transform: scale(0.84);
      }
    }
  }
}
