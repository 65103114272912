@import "../../assets/styles/mixins";
.ourstory {
  padding: 64px 11.25%;

  .heading {
    @include font(44px, 52px, 800, var(--fill-black));
    font-family: var(--font-heading);
    margin-bottom: 32px;
    color: #000126;
  }
  &__write {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;

    .description {
      @include font(16px, 22.4px, 500, var(--fill-dark-gray-2));
      width: 448px;
    }
    .b {
      display: none;
    }
  }
}
@include media-md {
  .ourstory {
    padding: 32px 6.25%;

    &__write {
      font-size: 16px;
      flex-direction: column;
      justify-content: center;
    }
    .heading {
      justify-content: center;
      margin-top: 16px;
      padding-left: 0px;
      text-align: center;
    }
    .description {
      width: 100%;
    }
    .d1 {
      display: none;
    }
    .d2 {
      display: none;
    }
    .b {
      display: flex;
      justify-content: center;
    }
    .bt {
      display: block;
      background-color: #ffffff;
      border: 1px solid #d4d4d4;
      border-radius: 16px;
      color: #676773;
      width: 122px !important;
      height: 41px !important;
      font-weight: 400;
      font-size: 16px;
    }
  }
}
@include media-sm {
  .ourstory {
    padding: 32px 6.25%;

    &__write {
      font-weight: 500;
      font-size: 14px;
      line-height: 0%;
      flex-direction: column;
      justify-content: center;
    }
    .heading {
      font-size: 30px;
      text-align: center;
      margin-bottom: 0px;
      margin-top: 0px;
    }
    .description {
      width: 100%;
      font-size: 14px;
      line-height: 148%;
    }
    .d1 {
      display: none;
    }
    .d2 {
      display: none;
    }
    .b {
      display: flex;
      justify-content: center;
    }
    .bt {
      display: block;
      background-color: #ffffff;
      border: 1px solid #d4d4d4;
      border-radius: 32px;
      color: #676773;
      width: 130px !important;
      height: 41px !important;
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      margin-top: 10px;
      padding: 5px 16px;
    }
  }
}
