@import "../../assets/styles/mixins";

.productCard {
  display: flex;
  flex-direction: column;
  max-width: 286px;
  padding-bottom: 24px;
  border-radius: 16px;
  border: 1px solid white;
  .product-image {
    border-radius: 16px;
    width: 286px;
    height: 286px;
    cursor: pointer;
  }
  .fabric {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    padding-left: 18px;
    color: var(--fill-neutral-black);
    max-width: 286px;
    cursor: pointer;
  }
  .price {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: var(--fill-neutal-gray2);
    max-width: 286px;
    padding-bottom: 1rem;
    padding-left: 18px;
    cursor: pointer;
  }
  .row {
    display: flex;
    gap: 12px;
    padding-left: 18px;
    max-width: 286px;
    .color {
      border-radius: 50%;
      width: 26px;
      height: 26px;
    }
  }
}
.productCard:hover {
  border: 1px solid var(--fill-gray);
}
@media (max-width: 640px) {
  .productCard {
    padding-bottom: 32px;
    max-width: 140px;
    .product-image {
      width: 140px;
      height: 140px;
    }
    .fabric {
      max-width: 140px;
      font-size: 14px;
      min-height: 60px;
      padding-top: 0.5rem;
      padding-bottom: 0px;
      margin-bottom: -6px;
      padding-left: 1px;
    }
    .price {
      font-size: 14px;
      padding-bottom: 0.5rem;
      padding-left: 1px;
    }
    .row {
      display: flex;
      max-width: 140px;
      justify-content: space-between;
      padding-left: 1px;
      .color {
        width: 16px;
        height: 16px;
      }
    }
  }
  .productCard:hover {
    border: 1px solid white;
  }
}
@media (min-width: 300px) and (max-width: 400px) {
  .productCard {
    .row {
      display: flex;
      justify-content: flex-start;
      gap: 7px;
    }
  }
}
.product-carousel {
  height: 286px;
  width: 286px;
  .slick-slide {
    .carousel-image {
      height: 272px;
      border-radius: 16px 16px 0px 0px;
    }
  }
  .slick-dots {
    bottom: -12px !important;
    li {
      width: 6px;
    }
    li button::before {
      font-size: 8px !important;
      color: var(--fill-light-gray-2);
    }
    .slick-active button::before {
      opacity: 1;
      color: #7b61ff;
      font-size: 6px;
      outline: none !important;
      outline-offset: 0px !important;
      line-height: 20px;
    }
  }
}