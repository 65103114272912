@import "../../assets/styles/mixins";

.certifications {
  width: 100%;

  padding: 64px 4.25%;
  @include flex(row, space-around, center);

  &__left {
    @include flex(column, center, flex-start);
    gap: 20px;

    .heading {
      @include font(20px, 25.1px, 700, var(--fill-black));
      font-family: var(--font-heading);
      margin-bottom: 4px;
      width: 100%;
    }

    &__first {
      display: flex;
      gap: 56px;
      border: 2px solid #ddf0ff;
      border-radius: 24px;
      padding: 24px;
      align-items: center;
      justify-content: center;

      .two {
        display: flex;
        gap: 56px;
      }
    }
  }

  &__right {
    @include flex(column, center, flex-start);
    gap: 20px;

    .heading {
      @include font(20px, 25.1px, 700, var(--fill-black));
      font-family: var(--font-heading);
      margin-bottom: 4px;
      width: 100%;
    }

    &__second {
      display: flex;
      gap: 56px;
      border: 2px solid #ddf0ff;
      border-radius: 24px;
      padding: 24px;
      align-items: center;
      justify-content: center;
    }
  }
}

@include media-lg {
  .certifications {
    @include flex(column, center, center);
    gap: 40px;

    .heading {
      text-align: center;
    }

    &__left {
      @include flex(column, center, center);
    }

    &__right {
      @include flex(column, center, center);
    }
  }
}

@include media-md {
  .certifications {
    padding: 44px 6.25% 64px 6.25%;
    @include flex(column, center, center);
    gap: 40px;

    .heading {
      @include font(24px, 32px, 800, #676773);
      font-family: var(--font-heading);
      text-align: center;
      margin-top: 0px;
    }

    &__left {
      &__first {
        @include flex(column, center, center);
        padding: 24px 32px;
        border-radius: 30px;
        gap: 16px;
      }
    }
    &__right {
      &__second {
        border-radius: 30px;
        gap: 24px;
        padding: 24px 69px;
      }
    }
  }
}
@include media-sm {
  .certifications {
    @include flex(column, center, center);

    .heading {
      @include font(24px, 32px, 800, #676773);
      font-family: var(--font-heading);
      margin-bottom: 4px;
      width: 100%;
      text-align: center;
    }

    &__left {
      &__first {
        @include flex(column, center, center);
        padding: 24px 32px;
        border-radius: 24px;
        img {
          width: 48%;
        }
        .two {
          gap: 16px;
        }
      }
    }

    &__right {
      &__second {
        border-radius: 30px;
        gap: 24px;
        padding: 24px 48px;
        img {
          width: 48%;
        }
      }
    }
  }
}
