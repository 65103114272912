@import "../../../assets/styles/mixins";
@import "../../../assets//styles/carousel.scss";

.testimonialsSection {
  padding: 82px 0px 36px 0px;
  background-color: var(--fill-deep-gray2);
  @include media-md {
    padding: 60px 0px 60px 0px;
  }
  &__content {
    width: calc(100vw - 550px);
    .mainContent {
      padding-left: 72px;
      padding-right: 72px;
      .firstLine {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        .title {
          margin-bottom: -10px;
          @include media-md{
            margin-bottom: 0px;
          }
        }
        .arrw {
          margin-right: -4rem;
          .left{
            float: left;
          }
          .right{
            float: right;
            padding-left: 20px;
          }
          img {
            height: 13px;
            cursor: pointer;
          }
          @include media-lg {
            display: none;
          }
        }
      }
      @include media-sm {
        padding-left: 6.25%;
        padding-right: 6.25%;
      }
    }
  }
  &__list {
    display: grid;
    grid-template-columns: 1.3fr 1fr;
    grid-gap: 40px;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }

  &__card {
    background-color: var(--fill-blue-secondary);
    padding: 20px;
    display: flex;
    border-radius: 24px;
    border: 1px solid var(--fill-gray);

    .content {
      margin-left: 16px;
      line-height: 22px;
    }

    .heading {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 27px;
    }

    .description {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: var(--fill-dark-gray-2);
      line-height: 25px;
      position: relative;
      padding-bottom: 24px;
    }

    .company {
      color: var(--fill-dark-gray-3);
      font-size: 16px;
      line-height: 22px;
      padding-top: 4px;
    }

    &:nth-child(1) {
      justify-self: flex-end;
    }

    &:nth-child(3) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
}
.testimonialList {
  &__testimonialList {
    padding: 64px 0px 64px 72px;
    margin-right: 30px;
    @include flex(row, flex-start, flex-start);
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
  }
}

.carouselView {
  &.testimonialList__testimonialList {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-row-gap: 10px;

    .carouselStep,
    .carouselStep img {
      overflow: visible;

      margin-bottom: 2px;
    }

    .carouselStep {
      margin-right: 16px;
      height: 100%;

      img,
      .title {
        border-radius: 8px;
      }
    }
  }
}

@include media-sm;
@include media-md;
@include media-lg {
  .slidercarousel {
    .temp {
      padding: 40px 6.25% 0px 6.25%;
      margin-bottom: 32px;
      text-align: center;

      display: block;
      .heading {
        @include font(30px, 37px, 800, var(--fill-black));
        font-family: var(--font-heading);
        text-align: center;
        margin: 0px;
      }
    }
    .arrw {
      display: none;
    }
  }
  .testimonialList {
    &__testimonialsWrapperr {
      padding: 40px 40px 19px 6.25%;
    }
  }

  .carouselView {
    &.testimonialList__testimonialList {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-row-gap: 10px;
      grid-column-gap: 10px;
      padding-right: 20px;
      .carouselStep,
      .carouselStep img {
        overflow: visible;

        margin-bottom: 2px;
      }

      .carouselStep {
        margin-right: 8px;
        height: 100%;

        img,
        .title {
          border-radius: 8px;
        }
      }
    }
  }
}

@include media-md {
  .slidercarousel {
    .temp {
      padding:40px 6.25% 0px 6.25%;
      display: block;
    }
  }
  .testimonialsSection {
    &__content {
      width: auto;
    }
  }
}
@include media-sm {
  .slidercarousel {
    .temp {
      text-align: center;

      display: block;
    }
  }
}
@include media-lg {
  .slidercarousel {
    .temp {
      .heading {
        @include font(32px, 115%, 800, var(--fill-black));
      }
    }
  }
  .testimonialList {
    &__testimonialList {
      padding: 40px 40px 19px 11.25%;
    }
  }
}
@include media-md;
@include media-sm {
  .testimonialList {
    &__testimonialList {
      padding: 40px 40px 19px 6.25%;
    }
  }
}
