.privacy__policy {
  color: var(--fill-black);
  font-style: regular;
  font-size: 14px;
  line-height: 21px;
  max-height: 384px;
  height: 384px;

  & > div {
    padding-right: 7px;
  }

  p {
    margin-bottom: 16px;
  }
}
@media (max-width: 768px) {
  .privacy__policy {
    font-size: 12px;
    line-height: 16px;
  }
}
