@import "../../assets/styles/mixins";

.marginBottom {
  margin-bottom: 32px;
}
#infoBtn {
  width: 312px;
  cursor: pointer;
}
.infoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  display: flex;
  justify-content: center;
  @include media-lg {
    width: 100%;
    margin-top: 48px;
  }
}
.bulletContainer {
  flex-direction: row;
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
}
