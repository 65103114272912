@import "../../assets/styles/mixins";
.pridecard {
  height: 256px;
  width: 304px;
  border-radius: 16px;
  background-color: #ffffff;
  border: 1px solid #cee4ff;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 16px 24px 48px 16px;
  .prideicon {
    height: 72px;
    width: 72px;
    text-align: center;
    margin-bottom: 16px;
  }
  .prideheading {
    text-align: center;
    @include font(20px, 28px, 700, var(--fill-black));
    margin-bottom: 16px;
  }
  .pridedescription {
    text-align: left;

    @include font(14px, 20px, 500, #676773);
  }
}
@include media-xl {
  .pridecard {
    height: 256px;
    width: 264px;
  }
}
@include media-lg {
  .pridecard {
    height: 256px;
    width: 328px;
  }
}
@include media-md;
@include media-sm {
  .pridecard {
    height: 280px;
    width: 280px;
    align-items: center;
    border-radius: 16px;
    padding: 8px 16px 24px 16px;

    .prideicon {
      .prideimg {
        height: 87.6px;
        width: 57.69px;
        text-align: center;
        margin-bottom: 24px;
      }
    }
    .prideheading {
      text-align: center;
      @include font(18px, 28px, 700, var(--fill-black));
      margin-bottom: 16px;
    }
    .pridedescription {
      text-align: left;

      @include font(16px, 21px, 400, #676773);
    }
  }
}
