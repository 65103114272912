@import "../../assets/styles/mixins";

.manufactureSection {
  display: flex;
  flex-direction: row;
  height: 90vh;
  padding: 40px 5.65%;
  justify-content: space-between;
  align-items: center;
  @include media-lg {
    flex-direction: column-reverse;
    height: auto;
    margin-bottom: 0px;
  }
  .widthAdjustment {
    width: 50%;
    @include media-lg {
      width: auto;
    }
  }
}
