@import "../../assets/styles/mixins";

.hero {
  background: var(--fill-white);
  position: relative;

  &__content {
    position: absolute;
    top: 0;
    padding-left: 11.25%;
  }
  &_container {
    display: flex;
    width: 100%;
  }
  &_content_area {
    height: auto;
    width: 50%;
  }
  &_section1 {
    background-image: linear-gradient(
      to bottom right,
      #3944b4 20%,
      #bb6bd9 100%,
      #fff1cc
    );
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 5.65%;
    justify-content: center;
  }
  &_heading {
    color: var(--fill-white);
    font-style: normal;
    font-weight: 800;
    font-size: 2.125rem;
    text-align: left;
    line-height: initial;
    margin: 0;
    padding-bottom: 20px;
    max-width: 85%;
 
  }
  &_subHeading {
    color: var(--fill-white);
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.688rem;
    max-width: 85%;
    
  }
  &_actionButton {
    font-size: 18px;
    margin-top: 32px;
    display: flex;
    font-weight: 700;
    border-radius: 8px;
    color: #0067e2;
    background-color: white;
    padding: 16px 40px;
    margin-bottom: 24px;
    min-width: 320px;
    height: 56px;
  }
  &_arrow {
    margin-left: 8px;
    margin-top: 3px;
  }
  &_radioContainer {
    height: 56px;
    background-color: #f2f8ff;
    justify-content: center;
    align-items: center;
    display: flex;
    gap:4px
  }
}
.mySlides {
  display: "none";
}
.section2{
  height: 60vh
 }
.herodot {
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: #d4d4d4;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.5s ease;
  cursor: pointer;
}
.active {
  background-color: #0067e2;
  margin-top: 2px;
}
.section2Img{
  height: 100%;
  width: 100%;
}
.activeRadioWrapper {
  border: 2px solid #0067e2;
  border-radius: 50%;
  padding: 1px;
  height: 20px;
  display: flex;
  justify-content: center;
  margin-left: 2px;
  margin-right: 2px;
}
/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}
@keyframes fade {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 1;
  }
}
.sliders_wrapper {
  display: flex;
  margin-top: 56px;
}
.herorequirementFormClass{
  width:560px
}

@include media-xl {
  .hero {
    &_heading {
      font-size: 1.875rem;
      max-width: 85%;
    }
    &_subHeading {
      font-size: 16px;
      line-height: 150%;
      max-width: 85%;
    }
  }
}
@include media-lg {
  .hero {
    &_heading {
      font-size: 1.875rem;
      max-width: 85%;
    }
    &_subHeading {
      font-size: 16px;
      line-height: 150%;
      max-width: 85%;
    }
    &_content_area {
      width: 100%;
      height: fit-content;
    }

    &_section1 {
      background-image: linear-gradient(
        to bottom right,
        #3944b4,
        #bb6bd9,
        #fff1cc
      );
      height:400px
    }
  }
  .section2{
    height:50vh;
    @include media-md{
      height: 40vh;
    }

  }
  .section2Img{
    height: 100%;
    width: 100%;
  }
  .sliders_wrapper {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 0px;
  }
}

@include media-md {
  .hero {
    &_heading {
      font-size: 1.875rem;
      max-width: 100%;
    }
    &_subHeading {
      font-size: 16px;
      line-height: 150%;
      max-width: 100%;
    }
    &_content_area {
      object-fit: contain;
      width: 100%;
    }
  }
  .sliders_wrapper {
    display: flex;  
    flex-direction: column-reverse;
  }
  // .section2Img{
  //   height: 50%;
  //   width: 100%;
  // }

}

@import "../../assets/styles/mixins";

.hero {
  background: var(--fill-white);
  position: relative;
  &__video {
    position: relative;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    object-fit: fill;
    filter: brightness(50%);
  }

  &__content {
    position: absolute;
    top: 0;
    padding-left: 11.25%;
    width: 100%;
    height: 100%;
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    justify-content: center;
    padding-bottom: 18px;
    z-index: 2;

    .hero__heading {
      color: var(--fill-white);
      font-style: normal;
      font-weight: 800;
      font-size: 2.75rem;
      text-align: left;
      line-height: initial;
      margin: 0;
      padding-bottom: 20px;
    }

    .hero__summary {
      color: var(--fill-white);
      font-style: normal;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      max-width: 780px;

      & > p {
        font-style: normal;
        font-weight: lighter !important;
        font-size: 15px;
        line-height: 22.4px;
        max-width: 320px;
        text-align: left;

        &:nth-child(2) {
          margin-top: 20px;
        }
      }
    }

    .hero__action {
      font-size: 16px;
      margin-top: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
    }
  }

  .scroll-down {
    position: absolute;
    left: 50%;
    bottom: 5%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @include media-lg {
    &__content {
      padding: 0 24px;
      align-items: center;
      .hero__heading {
        font-size: 40px;
        font-weight: 800;
        line-height: 115%;
        text-align: center;
      }
      .hero__summary p {
        font-size: 14px;
        line-height: 20px;
        text-align: center;
      }
    }
    .scroll-down {
      left: 48%;
    }
  }
  @include media-md {
    &__video {
      object-fit: cover;
    }

    &__content {
      padding: 0 24px;
      align-items: center;
      .hero__heading {
        font-size: 40px;
        font-weight: 800;
        line-height: 115%;
        text-align: center;
      }

      .hero__summary p {
        font-size: 16px;
        line-height: 132%;
        text-align: center;
      }

      .scroll-down {
        display: none;
      }
    }
  }
}
.customerdisplay{
  display: none;
}