@import "../../assets/styles/mixins";
.swatch {
  background-color: white;
  border-radius: 80px;
  position: fixed;
  border: 1px solid var(--fill-light-orange);
  z-index: 10;
  cursor: pointer;
}
#t1 {
  padding: 8px 0 0 8px;
  width: 112px;
  height: 112px;
  top: 46%;
  right: 3%;
}
#t2 {
  display: none;
  padding: 4px 0px 0px 4px;
  width: 64px;
  height: 64px;
  bottom: 7%;
  right: 7%;
  -webkit-tap-highlight-color: transparent;
}
@include media-sm {

  #t2 {
    display: block;
  }
  #t1 {
    display: none;
  }
}
.homefooterClass{
  padding: 0px 5.65% 0px 5.65%;
  @include media-lg{
    padding: 0px;
  }
}