@import "../../assets/styles/mixins";
.main-screen {
  padding: 182px 11.25% 226px 11.25%;
  .container404 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .heading {
      @include font(100px, 24px, 800, #ffd687);
    }
    .description {
      @include font(16px, 24px, 500, var(--fill-dark-gray-2));
      margin-bottom: 24px;
      @include media-sm {
        text-align: center;
      }
    }
    .btn404 {
      font-size: 16px;
      line-height: 19px;
      font-style: normal;
      font-weight: 700;

      border: white;
      color: #0067e2;
      cursor: pointer;
      height: 48px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 16px;
      gap: 10px;
      background: transparent;
      border: 1px solid #e5e5e5;
      border-radius: 8px;

      @include media-md {
        width: 218px;
        height: 48px;
        gap: 4px;
        align-items: center;
      }

      @include media-sm {
        width: 218px;
        height: 48px;
        align-items: center;
      }
    }
  }
}
