@import "../../assets/styles/mixins";
.requirementContainer{
    width:390px;
  @include media-lg
  {
    width:350px;
  }
  @include media-md {
    width:300px;
  }
}
.requirementForm {
  .formContent {
    max-height: 52vh;
    overflow-y: auto;
    margin-top: 16px;
    span {
      display: inline-block;
      line-height: 20px;
    }
    @include media-md {
      max-height: 60vh;
    }
  }
  .tickImg {
    height: 80px;
    margin-bottom: 40px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .successTitle {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
    text-align: center;
    line-height: 120%;
  }
  .successSubTxt {
    font-size: 14px;
    font-weight: 400;
    color: #626870;
    text-align: center;
  }

  input,
  textarea {
    padding: 10px 16px;
    width: 100%;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    border: 1px solid var(--fill-light-gray-2);
    &:active,
    &:focus,
    &:hover {
      outline: none;
    }

    &:focus {
      border: 1px solid var(--fill-blue-1);
      font-weight: 600;
    }

    &::placeholder {
      color: #92979c;
      font-size: 14px;
    }
  }
  .PhoneInput {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    border-radius: 8px;
    border: 1px solid transparent;
    &--focus {
      border: 1px solid var(--fill-blue-1);
    }

    input:focus {
      border: 0;
    }
  }
  .PhoneInputCountry {
    background: var(--fill-gray);
    background: var(--fill-gray);
    margin: 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top: 1px solid var(--fill-light-gray-2);
    border-bottom: 1px solid var(--fill-light-gray-2);
    border-left: 1px solid var(--fill-light-gray-2);
    padding: 10px 12px 10px 12px;
  }
  .PhoneInputCountryIcon {
    overflow: hidden;
    margin-right: 6px;

    &--border {
      box-shadow: none;
    }
  }

  .PhoneInputCountrySelectArrow {
    border-width: 5px;
    transform: translateY(4px);
    border-color: var(--fill-dark-gray-3);
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    opacity: 1;
    border-style: solid;
  }
  .field-contact {
    position: relative;

    .country-call-code {
      position: absolute;
      left: 70px;
      top: 38px;
      width: 34px;
      font-size: 14px;
      text-align: right;
  
    }
  
    input[type="tel"] {
      padding-left: 16px;
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
  
    }
  }
  .checkbox-group {
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    span {
      padding: 10px 12px !important;
      margin-right: 4px !important;
      font-weight: normal !important;
      white-space: nowrap !important;
    }

    input[type="checkbox"]:checked + span:before,
    input[type="radio"]:checked + span:before {
      top: 12px !important;
    }
    .radioButton,
    .checkboxButton {
      input[type="radio"],
      input[type="checkbox"] {
        display: none;
      }

      input[type="radio"] + span,
      input[type="checkbox"] + span {
        border: 1px solid #e5e5e5;
        border-radius: 8px;
        color: var(--fill-neutral-gray);
        display: block;
        font-size: 14px;
        font-weight: normal;
        height: auto;
        margin-right: 6px !important;
        padding: 10px 12px !important;
        white-space: nowrap;
      }

      input[type="radio"]:checked + span,
      input[type="checkbox"]:checked + span {
        background: #f2f8ff;
        color: #0067e2;
        font-weight: 600 !important;
        padding-left: 28px !important;
        position: relative;
        border: none;

        &::before {
          background: url("../../assets/images/CheckBlue.svg");
          content: "";
          height: 12px;
          left: 10px;
          position: absolute;
          top: 14px;
          width: 12px;
        }
      }

      input[type="radio"]:disabled + span,
      input[type="checkbox"]:disabled + span {
        opacity: 0.5;
      }

      &.withoutTick {
        input[type="radio"]:checked + span,
        input[type="checkbox"]:checked + span {
          padding-left: 12px;

          &::before {
            content: "";
            height: 0;
            width: 0;
          }
        }
      }
    }
  }

}
.formContent::-webkit-scrollbar {
  display: none;
}
.formContent {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.field {
  position: relative;
  margin-bottom: 8px;
  span,
  label {
    font-family: "Mulish", sans-serif;
    margin-bottom: 8px;
    font-size: 12px;
    color: var(--fill-neutral-gray2);
    display: inline-block;
    line-height: 20px;
  }
  .labelWidth {
    width: 100%;
  }
  .field-error p {
    font-size: 12px;
    line-height: 16px;
  }
}

.flex {
  display: flex;
}
.label {
  font-family: "Mulish", sans-serif;
  margin-bottom: 16px;
  font-size: 14px;
  color: var(--fill-dark-gray-2);
  display: inline-block;
  line-height: 20px;
  width: 100%;
}
.field {
  .checkbox-group {
    transition: margin 1s ease-in-out !important;
    &.right {
      margin-left: -100px;
    }

    &.left {
      margin-left: 0;
    }
  }
}
.controls {
  box-shadow: 0px 0px 3px rgba(0, 103, 226, 0.12);
  padding: 10px 4px;
  background-color: var(--fill-white);
  svg {
    pointer-events: none;
    path {
      fill: var(--fill-dark-gray-3);
    }
  }

  &.leftCTA {
    position: absolute;
    left: 0.5px;
    top: 25px;
    height: 40px;
    background-color: var(--fill-white);
    svg {
      transform: rotate(180deg);
    }
    @include media-md {
      width: 32px;
      height: 40px;
      top: 9px;
      left: -1px;
    }
  }

  &.rightCTA {
    position: absolute;
    right: 1px;
    top: 25px;
    height: 40px;
    background-color: var(--fill-white);
    @include media-md {
      width: 32px;
      height: 40px;
      top: 9px;
      right: -1px;
    }
  }
}
.buttonWidth {
  width: 100%;
  height:40px
}
.submit__container {
  padding: 16px 0px;
}
.dropdownClass{
  width:'100%';
  height:40px
}
.dropdownField{
  .ant-select-selection-item {
    padding: 14px 12px;
   font-size: 14px;
   margin-top: 8px
  }
}
