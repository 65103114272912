@import "../../assets/styles/mixins";

.brandImg1 {
  height: 307px;
  width: 284px;
  margin-bottom: 24px;
  @include media-md{
    height:auto;
    width:auto;
  }
}
.brandImg2 {
  height: 221px;
  width: 231px;
  @include media-md{
    height:auto;
    width:auto;
    margin-left: 24px;
  }
}
.brandImg3 {
  height: 211px;
  width: 218px;
  @include media-md{
    height:auto;
    width:auto;
  }
}
.imageAlignmentContainer{
    display: flex;
    // width:55%;
    @include media-lg{
      width:auto
    }
}
.imgAlignmentsection1{
    margin-right: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.imgAlignmentsection2{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;

}