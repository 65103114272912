@import "../../assets/styles/mixins";

.compliance {
  padding: 96px 11.25%;
  background: var(--fill-green-secondary);
  @include flex(column, center, center);
  &__first {
    .header {
      display: flex;
      justify-content: center;
      padding-bottom: 24px;
      .image {
        padding-right: 24px;
      }
      .heading {
        font-size: 40px;
        font-weight: 800;
        line-height: 48px;
      }
    }
    .header-info {
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      max-width: 728px;
      text-align: center;
      color: var(--fill-neutal-gray);
    }
    padding-bottom: 72px;
  }
  &__second {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 32px;
    .image {
      height: 136px;
      width: 136px;
    }
    .styles-module_tooltip__mnnfp {
      background-color: var(--fill-neutal-gray);
      font-size: 16px;
      font-weight: 600;
      line-height: 21px;
      text-align: center;
      min-width: 133px;
      max-width: 285px;
      padding: 4px 16px;
      margin-top: 26px;
      border-radius: 8px 8px 0px 0px;
    }
  }
}
@include media-md {
  .compliance {
    &__second {
      .image {
        width: 94px;
        height: 94px;
      }
    }
  }
}
@include media-sm {
  .compliance {
    padding: 64px 24px;
    &__first {
      padding-bottom: 64px;
      .header {
        flex-direction: column;
        padding-bottom: 16px;
        .image {
          @include communityImage;
        }
        .heading {
          font-size: 30px;
          text-align: center;
        }
      }
      .header-info {
        font-size: 16px;
        text-align: left;
      }
    }
    &__second {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 16px;
      .image {
        border-radius: 16px;
      }
      .styles-module_tooltip__mnnfp {
        min-width: 92px;
        max-width: 243px;
      }
    }
  }
}
