@import "../../../assets/styles/mixins";

.productDesign {
  margin-top: 48px;
  position: relative;
  &__images {
    display: none;
  }
}
.left-box {
  position: absolute;
  left: 0px;
  width: 80px;
  z-index: 10;
  height: 180px;
  background: white;
  
}
.right-box {
  position: absolute;
  right: 0px;
  width: 80px;
  z-index: 10;
  height: 180px;
  background: white;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.slider {
  .slick-list {
    padding-bottom: 60px;
     padding-left: 80px;
  }
  .slick-slide div {
    width: 208px;
    @include media-xl {
      width: 180px;
    }
    @include media-lg {
      width: 180px;
    }
    img {
      width: 208px;
  
      @include media-xl {
        width: 180px;
      }
      @include media-lg {
        width: 180px;
      }
    }
    transition: transform 0.2s;
  }
  .slick-arrow {
    z-index: 10;
    height: 40px;
    width: 40px;
  }
  .slick-prev {
    left: 1.5%;
    top:38%
  }
  .slick-next {
    right: 1.5%;
    top:38%
  }
  .image {
    height: 160px;
    cursor: pointer;
    border-radius: 16px;
  }
}

@include media-md {
  .productDesign {
    margin-top: 40px;
    .left-box,
    .right-box {
      display: none;
    }
    .slider {
      display: none;
      .slick-slide div {
        width: 150px;
        img {
          width: 150px;
        }
        transition: transform 0.2s;
      }
    }
    &__images {
      padding: 0px 24px;
      display: flex;
      justify-content: space-between;
      gap: 24px;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
    .image {
      width: 80px;
      height: 80px;
      cursor: pointer;
      border-radius: 16px;
    }
  }
}
@include media-sm {
  .productDesign {
    margin-top: 40px;
    .slider {
      .slick-slide div {
        width: 80px;
        margin-left: 0px;
      }

      .image {
        height: 80px;
        border-radius: 16px;
        width: 80px;
      }
      .slick-slide div:hover {
        width: 80px;
        height: 80px;
        margin-left: 0px;
      }
    }
  }
}
.text_blockSelection {
  position: absolute;
  font-size: 24px;
  font-weight: 700;
  bottom: 5px;
  color: white;
  text-align: center;
  color: white;
  text-align: center;
  font-weight: 700;
  display: flex;
  align-items: end;
  justify-content: center;
  height: 50px;
  padding: 16px;
  background-image: linear-gradient(to bottom,#00000000, #00000082);
  border-radius:0px 0px 16px 16px;
}
.categoryNaming{
  position: relative;
  bottom:54px;
  color: white;
  text-align: center;
  font-weight: 700;
  display: flex;
  align-items: end;
  justify-content: center;
  height: 50px;
  padding: 8px;
  background-image: linear-gradient(to bottom,#00000000, #00000082);
  border-radius:0px 0px 16px 16px;
}