@import "../../assets/styles/mixins";
.factoryIcon {
  border-radius: 16px;
  background: var(--Primary-Violet-3, #f9faff);
  min-width: 56px;
  height: 56px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.supplierNameContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  padding: 0 6.25%;
}
.supplierName {
  color: var(--Neutral-Black, #00142d);
  font-size: 28px;
  font-weight: 700;
  text-transform: capitalize;
  margin-left: 24px;
  line-height: 40px;
  margin-top: 8px;
}
.supplierBox {
  display: flex;
 
}
.supplierButton {
  border-radius: 8px;
  border: 1px solid var(--Primary-Blue-1, #cee4ff);
  background: var(--Primary-Blue-2, #f2f8ff);
  display: flex;
  padding: 12px 16px 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}
.supplierConnectTxt {
  color: var(--Primary-Blue, #0067e2);
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
}
.footerButton {
  position: fixed;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 40px;
  bottom: 0px;
  background-color: white;
  width: -webkit-fill-available;
  justify-content: center;
  display: flex;
}
