@import "../../assets/styles/mixins";

.page-footer {
  background-color: #f2f8ff;
  color: #333340;
  font-size: 14px;
  line-height: 140%;
  width: 100%;
  padding: 0 11.25% 0 11.25%;
  .main-heading {
    padding-top: 3rem;
    h2 {
      font-weight: 800;
    }
  }
  .upper-footer {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 16px;
    h3 {
      margin-bottom: 8px;
    }
    p {
      max-width: 192px;
    }
    .jaipur{
      max-width: 182px;
    }
  }
  .carousel {
    display: none;
  }
  hr {
    background: #d4d4d4;
    height: 1px;
    border: none;
    margin-top: 32px;
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-row-gap: 30px;
    grid-column-gap: 15px;
    .page-footer {
      &__block {
        &__header {
          margin-bottom: 16px;
          .symbol {
            margin-right: 8px;
            margin-bottom: -3px;
          }
        }

        &__description {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
        }
        .social-icons {
          display: grid;
          margin-top: 2rem;
        }
        .social-icon {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
        }
        .social-icon a {
          margin-left: 0.5rem;
        }
        &--left {
          margin-top: 41px;
        }

        &--wrapper {
          display: grid;
          grid-template-columns: 1.13fr 1fr;
          &--center {
            margin-top: 41px;
            display: flex;
            flex-direction: column;
            font-weight: 600;

            a {
              padding-bottom: 16px;
            }
          }
        }

        &--right {
          margin-left: 8px;
          font-size: 16px;
          line-height: 21px;
          margin-top: 41px;
          display: grid;
          button {
            background: transparent;
            border: none;
            outline: none;
            color: var(--fill-white);
            &:focus,
            &:active {
              outline: none;
            }
          }

          > div {
            display: flex;
            margin-bottom: 16px;
          }

          .contact {
            svg {
              margin-right: 8px;
            }
            display: flex;
            align-items: center;
            font-size: 14px;
          }
          .download {
            display: grid;
            p {
              font-family: Mulish;
              @include font(14px, 18px, 400, #676773);
              margin-bottom: 2%;
            }
          }
        }

        &.copyright,
        &.privacy-section {
          margin-bottom: 24px;
          position: relative;
          font-size: 14px;
          white-space: nowrap;
          color: #676773;
          div,
          button {
            line-height: 20px;
          }

          .btn-link {
            color: black;
            border-radius: 0;

            &:nth-child(2) {
              padding-right: 16px;
            }
            &:last-child {
              border-left: 1px solid var(--fill-light-gray-2);
              padding-left: 16px;
            }
          }
          .divider {
            position: absolute;
            top: -10px;
            left: 0px;
            height: 1px;
            width: 77.5vw;
            background: #d4d4d4;
          }
        }

        &.privacy-section .divider {
          display: none;
        }
      }
    }
  }
  .copyrights{
    margin-top: -10px;
    hr {
      background: #d4d4d4;
      height: 1px;
      border: none;
    }
    p{
      color: #676773;
      margin-bottom: 0px;
      padding-bottom: 20px;
    }
  }
}
@include media-lg {
  .page-footer {
    padding: 0px;
    .main-heading {
      padding-top: 3rem;
      h2 {
        font-weight: 800;
        padding-left: 6.25%;
      }
    }
   
    .upper-footer {
      display: none;
    }
    .carousel {
      display: block;
      .address {
        margin-right: 0px;
        margin-bottom: 0px;
        width: 300px;
        height: 160px;
        padding: 16px 16px 24px 16px;
        border: 0.5px solid #d4d4d4;
        border-radius: 16px;
      }
    }
    hr {
      display: none;
    }
    &__content {
      padding-left: 6.25%;
    }
    &__block {
      &--wrapper {
        grid-template-columns: 1fr 1fr !important;
        margin-top: -40px;
      }
      &--right {
        grid-template-columns: 1fr 1fr;
        text-align: left;
        margin-bottom: 16px;
        margin-top: 20px !important;
        .contact {
          font-size: 14px !important;
        }
        .mobile {
          padding-top: 7px;
        }
      }
    }

    .copyright {
      grid-row-start: 6;
    }

    &__description {
      margin-bottom: 24px;
    }
    .social-icons {
      grid-template-columns: 1fr 1fr !important;
    }

    &__content {
      flex-direction: column;
      grid-template-columns: 1fr;
      grid-row-gap: 0;
      line-height: 24px;
      font-size: 14px;

      .page-footer__block__header {
        margin-bottom: 16px;
        button {
          font-size: 14px;
        }
      }
      .privacy-section {
        flex-direction: column-reverse;
        align-items: flex-start;
        font-weight: 600;

        margin-top: 16px;

        .btn-link {
          padding-top: 16px;
          &:last-child {
            padding-left: 0 !important;
            border-left: 0 !important;
          }
          margin: 0;
        }
      }

      .copyright {
        font-size: 12px;
      }

      .copyright,
      .privacy-section {
        display: flex;
      }

      .privacy-section .divider {
        display: block !important;
        left: -28px !important;
      }
    }
    .copyrights{
    padding-bottom: 24px;
      padding-left: 6.25%;
    }
  }
}
@include media-md {
  .page-footer {
    padding: 0 0 0 0;
    .carousel {
      .address {
        width: 200px;
        height:200px;
      }
    }
    .main-heading {
      padding-left: 0px;
      padding-top: 40px;
      h2 {
        margin: 0px;
        padding-left: 6.25%;
      }
    }
    &__block {
      &--right {
        grid-template-columns: 1fr 1fr;
        text-align: left;
        margin-bottom: 16px;
        .contact {
          flex-direction: column;
          align-items: flex-start !important;
          justify-content: center !important;
          .number {
            margin-top: 2px;
          }
        }
        .download {
          p {
            font-size: 13px !important;
          }
        }
      }
      &--left {
        margin-top: 41px;
      }
      .social-icons {
        display: grid;
        margin-top: 2rem;
      }
    }

    .copyright {
      grid-row-start: 6;
    }

    &__description {
      margin-bottom: 24px;
    }

    &__content {
      flex-direction: column;
      grid-template-columns: 1fr;
      grid-row-gap: 0;
      line-height: 24px;
      font-size: 14px;
      padding: 0 0 0 6.25%;

      .page-footer__block__header {
        margin-bottom: 16px;
        button {
          font-size: 14px;
        }
      }

      .privacy-section {
        flex-direction: column-reverse;
        align-items: flex-start;
        font-weight: 600;

        margin-top: 16px;

        .btn-link {
          padding-top: 16px;
          &:last-child {
            padding-left: 0 !important;
            border-left: 0 !important;
          }
          margin: 0;
        }
      }

      .copyright {
        font-size: 12px;
      }

      .copyright,
      .privacy-section {
        display: flex;
      }

      .copyright .divider {
        display: none;
      }

      .privacy-section .divider {
        display: block !important;
        left: -28px !important;
      }
    }
  }
}
.homefooterClass{
  padding: 0px 5.65% 0px 5.65%;
}
