@import "../../assets/styles/mixins";

.collaboration {
  padding: 80px 11.25%;
  @include flex(column, center, center);
  &__first {
    .header {
      display: flex;
      justify-content: center;
      padding-bottom: 24px;
      .image {
        padding-right: 24px;
      }
      .heading {
        font-size: 40px;
        font-weight: 800;
        line-height: 48px;
      }
    }
    .header-info {
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      max-width: 992px;
      text-align: center;
      color: var(--fill-neutal-gray);
    }
    padding-bottom: 72px;
  }
  &__second {
    .box {
      background-color: var(--fill-footer);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      padding: 10px 0px;
      .close {
        margin: 0px 26px;
        width: 24px;
        height: 24px;
      }
    }
    .images {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      padding-top: 34px;
      gap: 34px;
      .image {
        width: 308px;
        height: 328px;
        border-radius: 8px;
      }
    }
  }
}
@include media-lg {
  .collaboration {
    &__second {
      .images {
        grid-template-columns: 1fr 1fr;
        place-items: center;
        .image {
          width: 258px;
          height: 268px;
        }
      }
    }
  }
}
@include media-sm {
  .collaboration {
    padding: 64px 24px;
    &__first {
      .header {
        flex-direction: column;
        padding-bottom: 16px;
        .image {
          @include communityImage;
        }
        .heading {
          font-size: 30px;
          line-height: 130%;
          text-align: center;
        }
      }
      .header-info {
        font-size: 16px;
        text-align: left;
      }
    }
    &__second {
      .box {
        padding: 16px;
        .logoo {
          width: 25px;
          height: 25px;
        }
        .logo-blue {
          width: 100px;
          height: 18px;
        }
        .close {
          width: 20px;
          height: 20px;
          margin: 0px;
        }
        .clothes-logo {
          width: 92px;
          height: 32px;
        }
      }
      .images {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-top: 32px;
        gap: 16px;
        .image {
          width: 148px;
          height: 156px;
        }
      }
    }
  }
}
