@import "../../assets/styles/mixins";
@import "../../assets/styles/carousel.scss";
.slidercarousel {
  width: 100%;
  background: #ffffff;
  .temp {
    display: flex;
    justify-content: space-between;
    padding-top: 64px;
    .heading {
      @include font(30px, 56px, 800, var(--fill-black));
      padding-left: 5.65%;
      font-family: var(--font-heading);
      justify-content: space-between;
      display: flex;
      text-align: left;
    }
    .arrw {
      margin-right: 6.25%;
    }
  }
}
.testimonialWrapper {
  &__testimonialsWrapper {
    padding: 0px 0px 64px 5.65%;
    @include flex(row, flex-start, flex-start);
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
  }
}

.carouselView {
  &.testimonialWrapper__testimonialsWrapper {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-row-gap: 10px;

    .carouselStep,
    .carouselStep img {
      overflow: visible;

      margin-bottom: 2px;
    }

    .carouselStep {
      margin-right: 16px;
      height: 100%;

      img,
      .title {
        border-radius: 8px;
      }
    }
  }
}
@include media-xl {
  .slidercarousel {
    width: 100%;
    background: #ffffff;

    .temp {
      display: block;
      padding-top: 64px;
      .heading {
        @include font(42px, 56px, 800, var(--fill-black));
        padding-left: 6.25%;
        text-align: left;
        font-family: var(--font-heading);
        justify-content: space-between;
        display: flex;
      }

      .arrw {
        display: none;
      }
    }
  }
}
@include media-lg {
  .testimonialWrapper {
    &__testimonialsWrapper {
      @include flex(row, flex-start, flex-start);
      width: 100%;
      overflow-x: scroll;
      overflow-y: hidden;
    }
  }
  .slidercarousel {
    .temp {
      display: block;
      .heading {
        padding-left: 6.25%;
        text-align: left;
      }
    }
  }
}

@include media-sm;
@include media-md;
@include media-lg {
  .slidercarousel {
    .temp {
      padding: 40px 6.25% 0px 6.25%;

      display: block;
      .heading {
        @include font(30px, 37px, 800, var(--fill-black));
        font-family: var(--font-heading);
        text-align: left;
        margin: 0px;
        padding-left: 0px;
      }
    }
    .arrw {
      display: none;
    }
  }
  .testimonialWrapper {
    &__testimonialsWrapper {
      padding: 0px 40px 40px 6.25%;
    }
  }

  .carouselView {
    &.testimonialWrapper__testimonialsWrapper {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-row-gap: 10px;
      grid-column-gap: 10px;
      padding-right: 20px;
      .carouselStep,
      .carouselStep img {
        overflow: visible;

        margin-bottom: 2px;
      }

      .carouselStep {
        margin-right: 8px;
        height: 100%;

        img,
        .title {
          border-radius: 8px;
        }
      }
    }
  }
}

@include media-md {
  .slidercarousel {
    .temp {
      padding: 40px 6.25% 0px 6.25%;
      display: block;
      .heading {
        @include font(32px, 115%, 800, var(--fill-black));
        text-align: left !important;
      }
    }
  }
}
@include media-sm {
  .slidercarousel {
    .temp {
      display: block;
      .heading {
        @include font(32px, 115%, 800, var(--fill-black));
        text-align: left;
      }
    }
  }
}
@include media-lg {
  .slidercarousel {
    .temp {
      .heading {
        @include font(32px, 115%, 800, var(--fill-black));
        text-align: left;
      }
    }
  }
  .testimonialWrapper {
    &__testimonialsWrapper {
      padding: 0px 40px 40px 5.65%;
    }
  }
}
@include media-md;
@include media-sm {
  .testimonialWrapper {
    &__testimonialsWrapper {
      padding: 0px 40px 40px 5.65%;
    }
  }
}
