@import "../../assets/styles/mixins";

.networkWrapper {
  background-color: var(--fill-sky-blue);
  @include flex(row, space-between, center);
  // width: 100vw;
  padding: 56px 11.25% 0 7.25%;
  img {
    height: auto;
    max-width: 546px;
  }
  &__contentWrapper {
    margin-left: 40px;
    width: 472px;
    .content {
      &__title {
        @include font(18px, 24.55px, 700, var(--fill-black));
        font-family: var(--font-heading);
      }
      &__heading {
        @include font(42px, 56px, 800, var(--fill-black));
        font-family: var(--font-heading);
        margin-bottom: 24px;
      }
      &__description {
        @include font(16px, 24px, 400, var(--fill-dark-gray-2));
        margin-bottom: 56px;
        padding-left: 30px;
        width: 90%;
      }
      &__counts {
        padding: 0px 6px;
      }
    }
  }
}

.countWrapper {
  @include flex(row, flex-start, flex-start);
  margin-bottom: 24px;

  &__icon {
    margin-right: 16px;
    margin-top: 6px;
  }
  &__content {
    @include flex(column, flex-start, flex-start);
    &_title {
      @include font(24px, 40px, 800, var(--fill-gray-1));
    }
    &_description {
      @include font(16px, 26px, normal, var(--fill-gray-1));
    }
  }
}

.certificatesWrapper {
  padding: 90px 11.25% 80px 11.25%;
  background-color: var(--fill-sky-blue);
  &__heading {
    @include flex(row, center, center);
    @include font(24px, 28px, 700, #000126);
    margin-bottom: 8px;
    width: 100%;
    text-align: center;
    padding-bottom: 24px;
  }

  &__list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    .certif {
      background-color: white;
      height: 104px;
      width: 168px;
      border-radius: 24px;
      text-align: center;
      margin-right: 32px;
      margin-bottom: 16px;
      border: 1px solid #ddf0ff;
      img {
        width: 100%;
        height: auto;
        padding: 18px 22px;
      }
    }
  }
}

@include media-xl {
  .networkWrapper {
    @include flex(column, center, center);

    &__mapWrapper {
      @include flex(row, center, center);
      margin-top: 44px;
      margin-bottom: 56px;
      width: 100%;
      img {
        width: 100%;
      }
    }

    &__contentWrapper {
      margin-left: 20px;
    }
  }
}

@include media-lg {
  .networkWrapper {
    &__mapWrapper {
      margin-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 0px;
    }
    &__contentWrapper {
      margin: 0px;
      width: 100%;
      .content {
        &__title {
          @include font(16px, 21.82px, 700, var(--fill-black));
          text-align: center;
        }
        &__heading {
          @include font(30px, 40.92px, 800, var(--fill-black));
          text-align: center;
          margin-bottom: 16px;
        }
        &__description {
          @include font(16px, 17.57px, 500, var(--fill-dark-gray-2));
          margin-bottom: 56px;
          text-align: left;
        }
        &__counts {
          padding: 0 60px;
        }
      }
    }
  }
  .countWrapper {
    &__content {
      &_title {
        @include font(20px, 27.32px, 800, var(--fill-gray-1));
      }
      &_description {
        @include font(14px, 22px, normal, var(--fill-gray-1));
      }
    }
  }
}

@include media-sm {
  .networkWrapper {
    padding: 0px;
    &__mapWrapper {
      margin-top: 0px;
      padding-top: 32px;
      padding-left: 16px;
      padding-right: 16px;
    }
    &__contentWrapper {
  
      .content {
        &__description {
          margin-bottom: 42px;
          line-height: 148%;
          width: 100%;
          padding-left: 0px;
        }
      }
    }
  }
}

@include media-sm;
@include media-md {
  .networkWrapper {
    &__mapWrapper {
      justify-content: center;
    }
    &__contentWrapper {
      width: 312px;
      .content {
        &__counts {
          padding: 0 6px;
        }
      }
    }
  }
  .certificatesWrapper {
    padding: 36px 24px 48px 24px;
    &__heading {
      @include font(24px, 40px, 700, #000126);
    }
    &__list {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      .certif {
        background-color: white;
        height: 80px;
        width: 152px;
        border-radius: 24px;
        text-align: center;
        margin-right: 0px;
        margin-bottom: 8px;
        border: 1px solid #ddf0ff;
        img {
          height: auto;
          width: 100%;
          padding: 5px 16px;
        }
      }
    }
  }
}
@include media-md {
  .networkWrapper {
    &__contentWrapper {
      padding-left: 6.25%;
      padding-right: 6.25%;
      width: 100%;
    }
  }
}
