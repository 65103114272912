@import "../../assets/styles/mixins";
.carrd {
  border-radius: 24px !important;
  width: 656px;
  padding: 24px;
  padding-top: 8px;
  padding-bottom: 48px;
  height: 220px;
  background-color: #f9faff;
  @include media-md{
     height:380px
  }
  .contentt {
    display: flex;
    gap: 15px;
    .left {
      .details {
        .name {
          font-size: 18px;
          font-weight: 700;
          line-height: 24px;
          
        }
        .sname {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: #676773;
        }
      }
      .adworddetails {
        margin-top: 0px;
        .name {
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 2px;
        }
      }
      .main_img {
        height: 128px;
        width: 88px;
        border-radius: 112px;
      }
      .adwordimg {
        height: 96px;
        width: 96px;
      }
    }
    .right {
      p {
        width: 224px;
        height: 168px;
        line-height: 21px;
      }
      .adwordstext {
        margin-top: 4px;
      }
    }
  }
  .carousel_wrap {
    display: flex;
    flex-direction: row;
    .middle {
      img {
        height: 12px;
        width: 12px;
      }
      .adwordmiddleimg {
        width: 9px;
        height: 9px;
        margin-right: 9px;
        margin-left: -5px;
      }
    }
  }
}
.adwordcard {
  width: 600px;
  height: 233px;
}
@include media-md {
  .carrd,
  .adwordcard {
    width: 296px;
    border-radius: 24px;
    padding: 12px 20px 24px 20px;

    .contentt {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .left {
        .details {
          text-align: center;
        }
        .adworddetails {
          margin-top: 8px;
          .name {
            font-size: 18px;
          }
        }
        .adwordimg {
          height: 160px;
          width: 160px;
          border-radius: 8px;
        }
      }
      .right {
        .adwordstext {
          margin-top: initial;
        }
      }
      .carousel_wrap {
        display: flex;
        flex-direction: row;
        .middle {
          img {
            height: 12px;
            width: 12px;
          }
          .adwordmiddleimg {
            height: 12px;
            width: 12px;
          }
        }
      }
    }
  }
}
.imageBox {
  width: 88px;
  height: 128px;
  border-radius: 112px;
  object-fit: contain;
  position: relative;
  top: 64px;
  left: 24px;
}
.details {
  // margin-left: 24px;
  .name {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    @include media-md{
      font-size: 14px;
    }
  }
  .sname {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #676773;
    @include media-md{
      font-size: 12px;
    }

  }
}
.adworddetails {
  margin-top: 0px;
  .name {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 2px;
  }
}
.main_Img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: fill;
}
.starContainer {
  width: 120px;
  height: 20px;
  margin-top: 8px;
  @include media-md{
    width: 100px;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
.testinmonialArrowContainer {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  img {
    width: 32px;
    height:32px;
    @include media-md {
      width: 16px;
      height:16px; 
    }
  }
}
.testimonialDescription{
  padding-bottom: 24px;
  padding-top: 12px;
}
.cardNameContainer{
  display: flex;
  justify-content: space-between;
  width: 80%;
  left: 84;
  position: relative;
}
