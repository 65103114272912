@import "../../assets/styles/mixins";

.page-header {
  &.scrolled {
    background: var(--fill-white);
    transition: background ease-in 0.2s;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
    .logo {
      filter: none;
    }
    .symbol {
      filter: none;
    }

    .link {
      color: var(--black);
      cursor: pointer;

      svg path {
        fill: var(--fill-black);
      }

      &--active {
        border-bottom: 2px solid var(--fill-black);
      }
    }
  }
  &.scrolledSwatch{
    background: white;
    .logo {
      filter: none;
    }
    .symbol {
      filter: none;
    }

    .link {
      color: var(--black);
      cursor: pointer;

      svg path {
        fill: var(--fill-black);
      }

      &--active {
        border-bottom: 2px solid var(--fill-black);
      }
    }
  }
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
   
    height: 100%;
  }

  &__navItems {
    @include flex(row, center, center);
    height: 100%;
  }

  &.scrolled .link-btn--desktop {
    border: 1px solid var(--fill-black);
    color: var(--fill-black);
    padding: 5px 16px;
  }
  &.scrolledSwatch .link-btn--desktop{
    border: 1px solid var(--fill-black);
    color: var(--fill-black);
    padding: 5px 16px;
  }
  &.scrolled .link-btn--mobile.link-btn {
    border: 1px solid #d4d4d4;
    color: #0067e2 !important;
    background-color: white;
    padding: 5px 16px;
    border-radius: 16px;
    height: 32px;
  }
  .logo {
    cursor: pointer;
    filter: brightness(0) invert(1);
    margin-right: 0.5rem;
  }
  .symbol {
    cursor: pointer;
    filter: brightness(0) invert(1);
    margin-right: 0.5rem;
    margin-bottom: -3%;
  }
  .link {
    @include flex(row, center, center);
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    height: 60%;
    margin-right: 32px;
    cursor: pointer;

    &--active {
      border-bottom: 2px solid var(--fill-black);
    }
    &--mobile {
      color: var(--fill-black);
      margin: 0px;
      margin-bottom: 32px;
      border: 0px;

      svg path {
        fill: var(--fill-black);
      }
      &.link--active {
        border: 0px;
        color: #0067e2;
      }
    }
  }

  .link-btn {
    @include flex(row, center, center);
    @include font(16px, 16.12px, 600, var(--fill-white));
    height: 36px;
    padding: 5px 16px;
    border-radius: 8px;
    &--mobile {
      color: var(--fill-black);
      padding: 0px;
      border-radius: 16px;
      height: 32px;
    }
  }

  .requestCtaBtn {
    display: none;
  }
  .requestCtaBtn--active {
    display: block;
  }

  .menuIcon {
    display: none;
  }
  .menuDropdown {
    display: none;
  }
@include media-lg{
  padding: 16px 24px;
  &.scrolled, &.scrolledSwatch {
    .menuIcon rect {
      fill: var(--fill-blue-1);
    }
  }
  .logo-blue {
    margin-bottom: 4%;
  }
}
  @include media-md {
    height: 56px;
    padding: 16px 24px;
    background: rgba(0, 0, 0, 0.1);
    &.scrolled, &.scrolledSwatch {
      .menuIcon rect {
        fill: var(--fill-blue-1);
      }
    }

    .requestCtaBtn {
      display: none;
    }
    .requestCtaBtn--active {
      display: block;
    }

 
  }
  @media (max-width: 992px) {
    &__navItems {
      display: none;
    }
    .menuIcon {
      display: block;
    }
    .menuDropdown {
      @include flex(column, center, center);
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100vw;
      padding: 16px 24px 32px 24px;
      min-height: 204px;
      background-color: var(--fill-blue-light);

      &__iconWrapper {
        @include flex(row, space-between, center);
        width: 100%;
      }
      &__items {
        @include flex(column, center, center);
        flex-grow: 1;
      }
    }
  }

  @include media-sm {
    padding: 16px 24px;
    &__navItems {
      display: none;
    }
    .menuIcon {
      display: block;
    }
    .menuDropdown {
      @include flex(column, center, center);
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100vw;
      padding: 16px 24px 32px 24px;
      min-height: 204px;
      background-color: var(--fill-blue-light);

      &__iconWrapper {
        @include flex(row, space-between, center);
        width: 100%;
      }
      &__items {
        margin-top: 24px;
        @include flex(column, center, center);
        flex-grow: 1;
      }
    }
  }

  @include media-sm {
    padding: 16px 24px;
  }
}
.requestBtn{
  height:36px;
  margin-left: 24px;
}
