.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
   width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  .request-modal {
    width: 400px;
    left: calc((100% - 400px) / 2);
    min-height: 512px;
    top: calc((100vh - 512px) / 2);
  }
  .privacy-modal {
    width: 840px;
    left: calc((100% - 840px) / 2);
    height: 512px;
    top: calc((100vh - 512px) / 2);
  }
  &__content {
    background: var(--fill-white);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    position: fixed;
    padding: 32px 32px 0px 32px;
    margin:24px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    h1 {
      margin: 0;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      color: var(--fill-black);
    }
  }
  &__body {
    padding-top: 16px;
  }
  &__close {
    width: 24px;
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  .modal {
    &__content {
      background: var(--fill-white);
      box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
      border-radius: 16px;
      position: fixed;
      padding: 24px 24px 0px 24px;
    }
    &__header {
      h1 {
        font-size: 16px;
        line-height: 22px;
      }
    }
    &__body {
      padding-top: 16px;
    }
    .request-modal {
      min-height: 446px;
      top: calc((100vh - 446px) / 2);
      width: 312px;
      left: calc((100% - 312px) / 2);
    }
    .privacy-modal {
      height: 518px;
      top: calc((100vh - 518px) / 2);
      width: 312px;
      left: calc((100% - 312px) / 2);
    }
  }
}
