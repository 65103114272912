@import "../../../assets/styles/mixins";

.hero-adwords {
  .hero__content {
    width: calc(100% - var(--form-container-width));
    justify-content: center;
    padding-left: 72px;
    @include media-lg {
      padding-left: 20px;
    }
    a {
      margin-bottom: 16px;
    }
    .logo {
      padding-top: 56px;
      cursor: pointer;
      filter: brightness(0) invert(1);
      display: none;
    }

    &_main {
      padding-top: 90px;
      .hero__heading {
        font-size: 48px;
        font-weight: 900;
        max-width: 836px;
        line-height: 58px;
        padding-bottom: 0px;
        @include media-xl {
          line-height: 58px;
        }
        @include media-lg {
          font-size: 28px;
          line-height: 150%;
          text-align: left;
        }
        @include media-md {
          line-height: 33px;
          text-align: center;
        }
      }
      .header {
        font-size: 43px;
      }
      .heroadwords__summary p {
        font-size: 22px;
        font-weight: 300;
        margin-top: 16px;
        margin-bottom: 50px;
        max-width: 710px;
        line-height: 150%;
        text-align: left;
        @include media-lg{
          font-size: 18px;
        }
      }

      .hero__usps {
        display: grid;
        place-items: flex-start;
        align-items: center;
        grid-row-gap: 24px;
        margin-top: 28px;
        border-radius: 16px;
        padding-bottom: 32px;
        &-item {
          display: flex;
          align-items: center;

          span {
            font-size: 20px;
            font-weight: 700;
            color: var(--fill-white);
            margin-left: 16px;
            flex: 1;
          }
        }
      }
    }
  }
}
